import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import Layout from 'components/Layout'
import MaterialProvider from 'components/theme'
import jaLocale from 'date-fns/locale/ja'
import NotFound from 'pages/NotFound'
import { Location, Route, Routes, useLocation } from 'react-router-dom'
import { dialogRoutes, privateRoutes } from './routers'
import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { UIAction } from 'app/slices/UI'

function App() {
  const location = useLocation()
  const dispatch = useAppDispatch()
  const state = location.state as {
    backgroundLocation?: Location
  }
  // const [auth, setAuth] = useState(
  //   document.cookie.split(';').some((item) => item.trim().startsWith('viewer='))
  // )
  const target = useAppSelector((s) => s.UI.target)
  // useEffect(() => {
  //   if (auth) return
  //   function checkPass() {
  //     if (!auth) {
  //       let sign = prompt('パスワードを入力してください')
  //       if (sign === '4s-mmsi4') {
  //         setCookie('viewer', 'true', 30)
  //         setAuth(true)
  //       } else {
  //         checkPass()
  //       }
  //     }
  //   }
  //   function setCookie(cName: string, cValue: string, expDays: number) {
  //     let date = new Date()
  //     date.setTime(date.getTime() + expDays * 24 * 60 * 60 * 1000)
  //     const expires = 'expires=' + date.toUTCString()
  //     document.cookie =
  //       cName + '=' + cValue + '; ' + expires + '; SameSite=None; Secure'
  //   }
  //   checkPass()
  // }, [auth])
  useEffect(() => {
    if (target) {
      const element = document.querySelector(target)
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        })
        setTimeout(() => {
          dispatch(UIAction.setTarget(''))
        }, 1000)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [target])
  useEffect(() => {
    if (!target) {
      const element = document.querySelector('#root')
      element?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])
  // if (!auth) return null
  return (
    <MaterialProvider>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={jaLocale}
      >
        {state && state.backgroundLocation && (
          <Routes>
            {dialogRoutes.map((route) => {
              return (
                <Route
                  key={route.path}
                  path={route.path}
                  element={<route.element requireRole={route.role} />}
                />
              )
            })}
            <Route
              element={
                <Layout>
                  <NotFound />
                </Layout>
              }
              path="*"
            ></Route>
          </Routes>
        )}
        <Routes location={state?.backgroundLocation || location}>
          {privateRoutes.map((route, index) => {
            return (
              <Route
                key={index}
                path={route.path}
                element={
                  <Layout>
                    <route.element requireRole={route.role} />
                  </Layout>
                }
              />
            )
          })}
          <Route
            element={
              <Layout>
                <NotFound />
              </Layout>
            }
            path="*"
          ></Route>
        </Routes>
      </LocalizationProvider>
    </MaterialProvider>
  )
}

export default App
