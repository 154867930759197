import { Box } from '@mui/material'
import ApplicationForm from 'components/Recruit/Application/Form'
import Top from 'components/Recruit/Top'
import { Helmet } from 'react-helmet'

export default function ApplicationPage() {
  return (
    <Box>
      <Helmet>
        <title>フォーシーズンズ | 応募フォーム</title>
      </Helmet>
      <Top />
      <ApplicationForm />
    </Box>
  )
}
