import { Box, Grid, Stack, Typography } from '@mui/material'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { getReport } from 'app/slices/Report'
import { useEffect } from 'react'

export default function Report() {
  const dispatch = useAppDispatch()

  const status = useAppSelector((s) => s.Report.status)
  const data = useAppSelector((s) => s.Report.data)
  useEffect(() => {
    if (status === 'init') dispatch(getReport('4s'))
  }, [status, dispatch])
  return (
    <Box mt={8}>
      <Grid
        container
        spacing={{
          xs: 2,
          md: 3,
          lg: 5,
        }}
        alignItems="stretch"
      >
        <Grid item xs={12}>
          <Typography component="h4" className="heading-3">
            中間報告会・決算報告会
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Stack gap={4}>
            {data.party.map((block, i) => {
              return (
                <Grid container key={i}>
                  {block.map((v, j) => {
                    const col = 12 / block.reduce((p, c) => p + c.collapse, 0)
                    return (
                      <Grid
                        item
                        className=""
                        sm={12}
                        md={col * v.collapse}
                        key={v.id}
                      >
                        <Box
                          sx={{
                            marginTop: `${v.marginTop || 0}px !important`,
                            borderRadius: `${v.borderRadius || 0}px`,
                            bgcolor: v.bgColor,
                            padding: v.padding && v.padding.join('px ') + 'px',
                          }}
                        >
                          {v.type === 'image' ? (
                            <Box
                              component="img"
                              width="100%"
                              src={v.value}
                              loading="lazy"
                            ></Box>
                          ) : (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: v.value || '',
                              }}
                              className="ql-editor view"
                            ></div>
                          )}
                        </Box>
                      </Grid>
                    )
                  })}
                </Grid>
              )
            })}
          </Stack>
        </Grid>
      </Grid>
    </Box>
  )
}
