import { yupResolver } from '@hookform/resolvers/yup'
import SendIcon from '@mui/icons-material/Send'
import { LoadingButton } from '@mui/lab'
import {
  Autocomplete,
  Box,
  Divider,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { RecruitAction, getRecruits } from 'app/slices/Recruit'
import FileUpload from 'components/FileUpload'
import { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import {
  AiFillContacts,
  AiFillMail,
  AiFillRead,
  AiFillStar,
} from 'react-icons/ai'
import { BsFillCalendar2DayFill } from 'react-icons/bs'
import { FaBirthdayCake, FaBookReader, FaSchool } from 'react-icons/fa'
import { MdCategory, MdOutlineWork } from 'react-icons/md'
import { TbLanguageKatakana } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'
import axiosClient from 'utils/axiosClient'
import * as yup from 'yup'
import Media from './Media'

interface FormInput {
  group: string
  position: string
  name: string
  kanaName: string
  email: string
  bod: Date
  schoolName?: string
  faculty?: string
  grade?: string
  media?: string
  writeContent?: string
  other?: string
  isFile?: number
}

export default function ApplicationForm() {
  const nav = useNavigate()
  const dispatch = useAppDispatch()
  const status = useAppSelector((s) => s.Recruit.status)
  const data = useAppSelector((s) => s.Recruit.list)
  const applicationStatus = useAppSelector((s) => s.Recruit.applicationStatus)
  useEffect(() => {
    if (status === 'init') dispatch(getRecruits())
  }, [status, dispatch])
  useEffect(() => {
    if (applicationStatus === 'init')
      dispatch(RecruitAction.setApplicationStatus('init'))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const schema = yup.object({
    group: yup.string().required().min(1),
    position: yup.string().required(),
    email: yup.string().required().email(),
    name: yup.string().required(),
    kanaName: yup.string().required(),
    bod: yup.date().required(),
    schoolName: yup.string().when(['group'], {
      is: '新卒採用',
      then: (value) => value.required().min(1),
    }),
    faculty: yup.string().when(['group'], {
      is: '新卒採用',
      then: (value) => value.required().min(1),
    }),
    grade: yup.string().when(['group'], {
      is: '新卒採用',
      then: (value) => value.required().min(1),
    }),
    isFile: yup.number().when(['group'], {
      is: 'キャリア採用',
      then: (value) => value.required().min(1),
    }),
  })
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
    setError,
  } = useForm<FormInput>({
    resolver: yupResolver(schema),
    defaultValues: {
      group: '',
      position: '',
      bod: new Date('1990/01/01'),
    },
  })

  const [file, setFile] = useState<File>()
  const [loading, setLoading] = useState(false)
  const onSubmit: SubmitHandler<FormInput> = async ({
    group,
    position,
    name,
    kanaName,
    email,
    bod,
    schoolName,
    faculty,
    grade,
    media,
    writeContent,
    other,
  }) => {
    setLoading(true)
    try {
      let formData = new FormData()
      if (file) formData.append('cv', file)
      formData.append(
        'data',
        JSON.stringify({
          group,
          position,
          name,
          kanaName,
          email,
          bod,
          schoolName,
          faculty,
          grade,
          media,
          writeContent,
          other,
        })
      )
      await axiosClient.post('/api/Application', formData)
      dispatch(RecruitAction.setApplicationStatus('completed'))
      nav('/recruit/application/complete')
    } catch (_error) {
      dispatch(RecruitAction.setApplicationStatus('error'))
      nav('/recruit/application/error')
    } finally {
      setLoading(false)
    }
  }
  const positions = data
    .filter((f) => f.group === getValues('group'))
    .map((v) => v.position)
  const groups = [
    '',
    '新卒採用',
    'キャリア採用',
    //  'インターン',
  ]
  return (
    <Box
      className="bg-recruit-pattern"
      component="form"
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      width="100%"
      sx={{
        py: 2,
        px: 1,
      }}
    >
      <Box className="container pt-10 pb-20 max-w-5xl">
        <Stack className="items-center justify-center gap-4 mb-8">
          <Typography
            className="heading-2 min-w-[150px] text-center"
            id="キャリア採用"
          >
            応募フォーム
          </Typography>
        </Stack>
        <Stack gap={2} className="max-w-5xl mx-auto">
          <Typography className="font-light leading-8 my-4">
            フォーシーズンズにご興味を持っていただきありがとうございます。
            <br />
            下記の各記入欄に必要事項をご入力の上、送信をお願いいたします。
            <br />
            内容を確認次第、7営業日以内に担当者よりご連絡差し上げます。
            <br />
            <br />
            尚、個人情報、機密情報などお問い合わせ内容によってはお答えしかねる場合がございますので、予めご了承下さい。
          </Typography>
          <Autocomplete
            options={groups}
            fullWidth
            value={getValues('group')}
            getOptionDisabled={(op) => op === ''}
            getOptionLabel={(option) => option || '応募形式選択'}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  size="small"
                  label={
                    <span className="flex items-center gap-1">
                      <MdCategory className="text-lg mt-0.5" />
                      応募形式
                      <small className="text-red-600 -translate-y-1">
                        必須
                      </small>
                    </span>
                  }
                  variant="outlined"
                  error={'group' in errors}
                />
              )
            }}
            onChange={(_event, val) => {
              if (val)
                setValue('group', val as any, {
                  shouldValidate: true,
                })
            }}
            sx={{
              '& fieldset': {
                borderColor: 'group' in errors ? '#f00' : '#0000003b',
              },
            }}
          />

          <Autocomplete
            options={['', ...positions]}
            fullWidth
            disabled={!getValues('group')}
            value={getValues('position')}
            getOptionLabel={(option) => option || '職種選択'}
            getOptionDisabled={(op) => op === ''}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  size="small"
                  label={
                    <span className="flex items-center gap-1">
                      <MdOutlineWork className="text-lg mt-0.5" />
                      職種
                      <small className="text-red-600 -translate-y-1">
                        必須
                      </small>
                    </span>
                  }
                  variant="outlined"
                  error={'position' in errors}
                />
              )
            }}
            onChange={(_event, val) => {
              if (val)
                setValue('position', val as any, {
                  shouldValidate: true,
                })
            }}
            sx={{
              '& fieldset': {
                borderColor: 'position' in errors ? '#f00' : '#0000003b',
              },
            }}
          />
          <TextField
            variant="outlined"
            disabled={!getValues('group') || !getValues('position')}
            margin="dense"
            fullWidth
            size="small"
            label={
              <span className="flex items-center gap-1">
                お名前
                <small className="text-red-600 -translate-y-1">必須</small>
              </span>
            }
            placeholder="例）採用　太郎"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AiFillContacts className="text-2xl" />
                </InputAdornment>
              ),
            }}
            {...register('name')}
            error={'name' in errors}
          />
          <TextField
            variant="outlined"
            disabled={!getValues('group') || !getValues('position')}
            margin="dense"
            fullWidth
            size="small"
            placeholder="例）サイヨウ　タロウ"
            label={
              <span className="flex items-center">
                お名前（カナ）
                <small className="text-red-600 -translate-y-1">必須</small>
              </span>
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <TbLanguageKatakana className="text-2xl" />
                </InputAdornment>
              ),
            }}
            {...register('kanaName')}
            error={'kanaName' in errors}
          />
          <TextField
            variant="outlined"
            disabled={!getValues('group') || !getValues('position')}
            margin="dense"
            fullWidth
            size="small"
            placeholder="例）〇〇@〇〇.co.jp"
            label={
              <span className="flex items-center gap-1">
                メールアドレス
                <small className="text-red-600 -translate-y-1">必須</small>
              </span>
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AiFillMail className="text-xl" />
                </InputAdornment>
              ),
            }}
            {...register('email')}
            error={'email' in errors}
          />
          <DatePicker
            label={
              <p className="flex items-center gap-1">
                <FaBirthdayCake className="text-lg" />
                <span>生年月日</span>
                <small className="text-red-600 -translate-y-1">必須</small>
              </p>
            }
            openTo={'year'}
            views={['year', 'month', 'day']}
            inputFormat="yyyy年MM月dd日"
            value={getValues('bod')}
            disabled={!getValues('group') || !getValues('position')}
            maxDate={
              new Date(new Date().getTime() - 1000 * 3600 * 24 * 365 * 15)
            }
            onChange={(date) => {
              if (date) {
                if (date instanceof Date && !isNaN(date.valueOf())) {
                  setValue('bod', date, {
                    shouldValidate: true,
                  })
                }
              }
            }}
            onError={(error) => {
              setError('bod', {
                message: error?.toString(),
              })
            }}
            renderInput={(params) => <TextField {...params} size="small" />}
          />
          <Divider />
          {getValues('group') === '新卒採用' && (
            <Stack gap={2}>
              <TextField
                variant="outlined"
                disabled={!getValues('position')}
                margin="dense"
                fullWidth
                size="small"
                label={
                  <span className="flex items-center gap-1">
                    学校名
                    <small className="text-red-600 -translate-y-1">必須</small>
                  </span>
                }
                placeholder="例）〇〇"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FaSchool className="text-xl" />
                    </InputAdornment>
                  ),
                }}
                {...register('schoolName')}
                error={'schoolName' in errors}
              />
              <TextField
                variant="outlined"
                disabled={!getValues('position')}
                margin="dense"
                fullWidth
                size="small"
                label={
                  <span className="flex items-center gap-1">
                    学部
                    <small className="text-red-600 -translate-y-1">必須</small>
                  </span>
                }
                placeholder="例）〇〇"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FaBookReader className="text-xl" />
                    </InputAdornment>
                  ),
                }}
                {...register('faculty')}
                error={'faculty' in errors}
              />
              <TextField
                variant="outlined"
                disabled={!getValues('position')}
                margin="dense"
                fullWidth
                size="small"
                label={
                  <span className="flex items-center gap-1">
                    学年
                    <small className="text-red-600 -translate-y-1">必須</small>
                  </span>
                }
                placeholder="例）〇〇"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <BsFillCalendar2DayFill className="text-xl" />
                    </InputAdornment>
                  ),
                }}
                {...register('grade')}
                error={'grade' in errors}
              />
              <FileUpload
                disabled={!getValues('position')}
                file={file}
                onSelectFile={(f) => {
                  setFile(f)
                }}
                title={file ? <>(再アップロード)</> : '履歴書'}
                subText={
                  <>
                    <span className="text-xs text-center">
                      ※履歴書が用意してあればアップロードいただけます
                    </span>
                    <span className="text-xs text-center">
                      (Word、Excel、PDFなど対応)
                    </span>
                  </>
                }
              />
            </Stack>
          )}

          {getValues('group') === 'キャリア採用' && (
            <Stack gap={2}>
              <FileUpload
                disabled={!getValues('position')}
                file={file}
                onSelectFile={(f) => {
                  setFile(f)
                  setValue('isFile', 1, {
                    shouldValidate: true,
                  })
                }}
                error={'isFile' in errors}
                title={file ? <>(再アップロード)</> : '職務経歴書'}
                subText={
                  <>
                    <span className="text-xs text-center">
                      (Word、Excel、PDFなど対応)
                    </span>
                  </>
                }
              />
              <Media
                disabled={!getValues('position')}
                onChange={(val) => {
                  setValue('media', val, {
                    shouldValidate: true,
                  })
                }}
              />
            </Stack>
          )}

          <TextField
            variant="outlined"
            margin="dense"
            fullWidth
            disabled={!getValues('group') || !getValues('position')}
            label={
              <span className="flex items-center gap-1">
                <AiFillStar className="text-2xl mt-1" />
                自由記入欄
              </span>
            }
            placeholder="自由に入力して下さい"
            InputLabelProps={{
              shrink: true,
            }}
            sx={{
              '& textarea': {
                mt: 1,
              },
            }}
            multiline
            minRows={5}
            {...register('writeContent')}
            error={'writeContent' in errors}
          />
        </Stack>
        <Divider
          sx={{
            mt: 2,
          }}
        />
        <TextField
          variant="outlined"
          margin="dense"
          fullWidth
          size="small"
          label={
            <span className="font-medium text-lg flex items-center gap-2 text-amber-800">
              <AiFillRead className="text-2xl mt-1" />
              個人情報保護方針
            </span>
          }
          multiline
          defaultValue={privacy}
          maxRows={12}
          InputProps={{
            readOnly: true,
          }}
          sx={{
            mt: 2,
            '& label': {
              transform: 'translate(14px, -12px) scale(1)',
            },
            '& .MuiInputBase-root': {
              background: '#fff7 !important',
            },
            '& textarea': {
              color: '#444 !important',
              mt: 1,
            },
          }}
        />
        <Divider
          sx={{
            mt: 2,
          }}
        />

        <Stack className="flex-row justify-center my-4">
          <LoadingButton
            type="submit"
            disabled={Object.keys(errors).length > 0}
            variant="contained"
            loading={loading}
            loadingPosition="end"
            endIcon={<SendIcon />}
            sx={{
              px: 4,
              background: (theme) =>
                `linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)`,
              boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .3)',
              '&:disabled': {
                background: (theme) => theme.palette.action.disabledBackground,
              },
            }}
          >
            個人情報保護方針に同意で送信する
          </LoadingButton>
        </Stack>
      </Box>
    </Box>
  )
}

const privacy = `
制定日：2011 年 10 月 1 日
フォーシーズンズ株式会社

代表取締役 松浦 世裕

フォーシーズンズ株式会社（以下 、「弊社」といいます。）は 、ICT（Information and Communication
Technology）とデザインの融合により、運用の最適化や ICT インフラの見直し・改善を継続的に支援するにあたり、個人情
報を含む機密情報を取り扱う場面が多く存在します。それら情報を取り扱うことの社会的責任、個人情報保護の重要性を
充分に認識し、個人情報の適正な取り扱いを維持・向上させるため、以下の基本的な方針に従い、個人情報の保護に努め
ます。

１．弊社のすべての事業で取り扱う個人情報及び役員などを含む社員及び従業員の個人情報（以下、「個人情報」とい
います。）について、個人情報保護法及び事業に関連する国が定める指針その他の規範などを遵守いたします。
２．弊社は、個人情報の利用目的を明確に定めるとともに、その利用目的の達成に必要な範囲内で適正に個人情報を取
り扱います。また、個人情報を正確かつ最新の内容に保つよう努めます。
３．弊社は、個人情報への不正アクセス、紛失、破壊、改ざん、漏えい滅失または破棄などのリスクに対して、適切な措置を
講じます。
４．取得した個人情報の一部または全部を委託する場合、弊社の基準を満たしている者を選定し、契約などにより適切な
措置を講じます。
５．弊社は、本人からの当該個人情報の開示、訂正、削除、利用停止の要請及び苦情や相談に対して遅延なく対応いた
します。
６．弊社は、個人情報保護の推進のため、社内の体制及び安全管理措置その他必要な措置の継続的な改善に努めま
す。

個人情報の利用目的について
弊社は、次の利用目的の範囲内でのみ個人情報を取り扱います。

・弊社サービスに関する販売、提供、情報提供
・お問合せ対応
・商談、打ち合わせ、連絡
・弊社施設への入退室管理
・受託業務の契約履行のために、委託先から提供された情報
・広告宣伝代理
・インターネットホームページの企画、立案、制作
・採用応募者への採用情報の発信、採用選考
・採用業務管理
・従業者、退職者などの雇用及び人事管理

個人情報の委託について
弊社は、取得した個人情報を適切に管理し、次の場合を除き、第三者に提供することはありません。

・本人の同意がある場合
・弊社の役員に関する情報で商法上などの定めによって公開する場合で、あらかじめ第三者提供などについて本人に通知また
は公表している場合
・委託契約において、その目的の範囲内で個人情報の取り扱いの全部または一部を委託する場合
・共同利用（グループ企業で統合的なサービス提供のため利用・親子兄弟会社の間で利用・外国の会社と利用）
・法令に基づく場合
・人の生命、身体または財産の保護のために必要がある場合であって、本人の同意を得ることが困難である場合
・公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難であ
る場合
・国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行する必要がある場合であって、本人
の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがある場合
・合併その他の事由による事業の承継に伴って個人情報を提供する場合であって、承継前の利用目的の範囲内で当該個
人情報を取り扱う場合

個人情報に関するお問い合せ窓口
弊社は、個人情報保護に関する開示など要望及び苦情や相談に対し、以下の窓口を設置します。

フォーシーズンズ株式会社
個人情報保護窓口
住所：〒103-0013 東京都中央区日本橋人形町 1-1-10 麻業会館 5 階
電話：03-5614-0746
FAX：03-5614-0747
E-mail：info@0004s.com
          `
