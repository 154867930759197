import { Box, Stack, Typography } from '@mui/material'

export default function Top() {
  return (
    <Box className="h-[350px] relative">
      <Stack className="absolute inset-0">
        <Box
          component="img"
          src="/static/work-top.png"
          alt="work-top"
          className="w-full h-full object-cover"
        ></Box>
      </Stack>
      <Stack className="relative text-white z-10 container h-full">
        <Stack className="h-full w-full justify-center gap-6">
          <Stack className="items-start text-shadow">
            <Stack className="justify-center items-center ml-10">
              <Box
                component="img"
                src="/static/work-white.svg"
                className="drop-shadow-md"
              ></Box>
              <Typography className="font-medium" mt={1}>
                事業内容・仕事内容
              </Typography>
            </Stack>
          </Stack>
          <Stack className="text-shadow">
            <Typography className="font-medium">
              既成概念にとらわれず価値を共創し続ける。
            </Typography>
            <Typography className="font-medium">
              私たちの持つ強みと事業を紹介します。
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  )
}
