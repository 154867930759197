import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Grid, Stack, Tab, Typography } from '@mui/material'
import LinkButton from 'components/LinkButton'
import { IRecruit } from 'interfaces'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

interface Props {
  data: IRecruit[]
}
export default function Career({ data }: Props) {
  const nav = useNavigate()
  const [value, setValue] = useState(data[0].position)

  return (
    <Box className="bg-recruit-pattern">
      <Box className="container py-20">
        <Stack className="items-center justify-center gap-4 mb-8">
          <Typography
            className="heading-2 min-w-[150px] text-center"
            id="キャリア採用"
          >
            キャリア採用
          </Typography>
        </Stack>
        <Grid
          container
          spacing={{
            xs: 1,
            sm: 2,
            md: 3,
            lg: 4,
          }}
          alignItems="stretch"
        >
          <Grid item xs={12} md={7}>
            <Typography className="font-medium text-xl">
              あなたの思い描く未来をかなえたい。
            </Typography>
            <Typography className="font-light leading-8 my-4">
              未経験で入社し、ひたすら努力し最短で役職がついたエンジニア
              <br />
              新人の頃からまじめな頑張りとコミュニケーションでお客様から指名され続けるエンジニア
              <br />
              プロジェクトをまとめチームメンバーから頼られるエンジニア
              <br />
              <br />
              フォーシーズンズにはそんなエンジニアが在籍しています。
              <br />
              <br />
              私たちに求められるスキルは多岐にわたり、必要な知識は変化が激しく幅広い。
              <br />
              常に未知なる問題にぶつかります。
              <br />
              業界動向を常に把握し、自分が目指す方向を見定め、努力を怠らない。
              <br />
              スキルアップのため、皆試行錯誤の日々を過ごしています。
              <br />
              技術を追い求める、リーダーシップを発揮する、一歩先行く価値を提供する。
              <br />
              あなたの思い描くエンジニア像はなんですか？
              <br />
              フォーシーズンズは理想のエンジニアになるべく努力するあなたを全力でサポートしたいと思っています。
              <br />
              <br />
              私たちと一緒に未来に前進できる方の応募をお待ちしています。　
            </Typography>
          </Grid>
          <Grid item xs={12} md={5}>
            <Stack gap={4} className="p-4 lg:p-0 mb-10">
              <Box
                component="img"
                src="/static/career01.png"
                alt="career01"
                sx={{
                  borderRadius: 3,
                  boxShadow: '8px 8px 0px #CDA83F',
                }}
              />
              <Box
                component="img"
                src="/static/career02.png"
                alt="career02"
                sx={{
                  borderRadius: 3,
                  boxShadow: '8px 8px 0px #CDA83F',
                }}
              />
            </Stack>
          </Grid>
        </Grid>
        <Typography className="heading-3 mt-10">採用フロー</Typography>
        <Typography className="font-light my-4">
          面接はお互いをよく知る重要な場です。じっくりお話をすることを大切にしています。
        </Typography>
        <Stack className="md:flex-row md:justify-around md:gap-10 gap-0 items-center py-4">
          {follow.map((v) => {
            return (
              <Stack key={v} className="recruit-follow">
                <Typography>{v}</Typography>
              </Stack>
            )
          })}
        </Stack>
        <Stack className="my-2">
          <Typography className="font-light text-sm leading-6">
            ※応募の秘密は厳守します
            <br />
            ※書類選考の結果は1週間以内に、合否に関わらずご連絡差し上げます
            <br />
            ※応募書類はご返却できませんので、予めご了承下さい
          </Typography>
        </Stack>
        <Typography className="heading-3 mt-10">募集要項</Typography>
        {data.length > 0 && (
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList
                onChange={(_e, val) => {
                  setValue(val)
                }}
              >
                {data.map((v, i) => {
                  return (
                    <Tab
                      value={v.position}
                      label={v.position}
                      key={v._id}
                      className="rounded-t-lg py-2 px-4"
                      sx={{
                        border: '1px solid #1D5DBA',
                        background:
                          v.position === value ? '#1D5DBA' : undefined,
                        color:
                          v.position === value ? '#fff !important' : '#1D5DBA',
                      }}
                    />
                  )
                })}
              </TabList>
            </Box>
            {data.map((v, i) => {
              return (
                <TabPanel
                  value={v.position}
                  key={v._id}
                  className="bg-white"
                  sx={{
                    mt: '-1px',
                    border: '1px solid #1D5DBA',
                  }}
                >
                  {v.blocks.map((block, i) => {
                    return (
                      <Grid
                        key={block.id}
                        container
                        margin={0}
                        justifyContent={{
                          md: 'center',
                          lg: 'flex-start',
                        }}
                        alignItems="stretch"
                        className="flex-1 relative"
                        sx={{
                          p: 0,
                          m: 0,
                          py: 2,
                          borderBottom: '1px solid #D1E5F8',
                        }}
                      >
                        <Grid item margin={0} className="w-full" sm={12} md={3}>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: block.category,
                            }}
                            className="ql-editor view"
                          ></div>
                        </Grid>
                        <Grid
                          item
                          margin={0}
                          className="w-full"
                          sm={12}
                          md={9}
                          sx={{
                            pl: '1rem',
                          }}
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: block.value,
                            }}
                            className="ql-editor view"
                          ></div>
                        </Grid>
                      </Grid>
                    )
                  })}
                </TabPanel>
              )
            })}
          </TabContext>
        )}
        <Stack
          className="justify-center items-center py-10 bg-[#1D5DBA] cursor-pointer hover:bg-[#1650a6] transition-all"
          onClick={() => nav('/recruit/application')}
        >
          <LinkButton
            to="/recruit/application"
            text="応募する"
            arrowIcon
            sx={{
              border: 'solid currentColor',
              borderWidth: '2px 0',
              width: 200,
              color: '#fff',
            }}
          />
        </Stack>
      </Box>
    </Box>
  )
}
const follow = ['書類選考', '1次面接・適性検査', '2次面接', '内定']
