import { Box, Grid, Stack, Typography } from '@mui/material'

export default function Develop() {
  return (
    <Box className="container py-10">
      <Stack className="items-center justify-center gap-4 mb-8">
        <Typography className="heading-2" id="SI事業部／Web開発">
          SI事業部／Web開発
        </Typography>
        <Typography textAlign="center">
          企画開発とデザインの両面からお客様の課題解決に取り組む
          <br />
          Webアプリ開発・Webサイト制作
        </Typography>
      </Stack>
      <Typography className="font-light">
        すべてはエンドユーザが快適に利用できるサービスのために。サービスの提供者であるお客様のその思いを実現するために、フォーシーズンズはお客様の声に真摯に耳を傾け、開発とデザインの両面から課題解決に向けて最善を尽くします。
      </Typography>
      <Box className="max-w-xl mx-auto px-[2%]">
        <Stack className="bg-bottom relative pt-[100%]">
          <Box
            component="img"
            src="/static/si-map.png"
            alt="si-map"
            className="absolute top-0 left-0 w-full h-full object-contain"
          ></Box>
        </Stack>
      </Box>
      <Typography className="bg-[#ECE2C5] text-center py-2 font-medium">
        Webアプリ開発・Webサイト制作におけるフォーシーズンズの強み
      </Typography>
      <Box className="lg:px-10 p-4 bg-white">
        <Grid
          container
          spacing={{
            xs: 1,
            sm: 1.5,
            md: 2.5,
          }}
          justifyContent="space-evenly"
          alignItems="stretch"
        >
          <Grid item xs={12} md={4}>
            <Stack
              sx={{
                border: '1px solid #CDA83F',
                px: 2,
                height: '100%',
              }}
            >
              <Typography
                className="text-[#CDA83F] font-medium text-center py-4"
                sx={{
                  borderBottom: '2px dotted #CDA83F',
                }}
              >
                チームでプロジェクトを遂行
              </Typography>
              <Typography className="text-sm md:p-2 p-1 leading-7">
                全ての工程を自社スタッフが担当しているため、共通意識のもと、効率的に高い品質のサービスが提供できます。お客様をも含めたひとつのチームとして連携し、満足度の高いシステムを開発します。
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={4}>
            <Stack
              sx={{
                border: '1px solid #CDA83F',
                px: 2,
                height: '100%',
              }}
            >
              <Typography
                className="text-[#CDA83F] font-medium text-center py-4"
                sx={{
                  borderBottom: '2px dotted #CDA83F',
                }}
              >
                プロフェッショナルとしての意識
              </Typography>
              <Typography className="text-sm md:p-2 p-1 leading-7">
                プロフェッショナルとして、技術へのこだわりを忘れず、お客様のご要望の一歩先行くご提案ができるよう努めています。日々進歩していくWeb業界において常に知識を蓄え、技術力を高めることを怠りません。
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={4}>
            <Stack
              sx={{
                border: '1px solid #CDA83F',
                px: 2,
                height: '100%',
              }}
            >
              <Typography
                className="text-[#CDA83F] font-medium text-center py-4"
                sx={{
                  borderBottom: '2px dotted #CDA83F',
                }}
              >
                お客様との密なコミュニケーション
              </Typography>
              <Typography className="text-sm md:p-2 p-1 leading-7">
                お客様のご要望に真摯に寄り添った開発ができるよう、お客様との対話を大切にしています。Webサイト公開後の支援やご相談などにも柔軟に対応、お客様と長いお付き合いができることも、当社の強みです。
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <Box className="my-10">
        <Typography className="heading-3">主な実績</Typography>
        <Typography className="font-light my-4">
          官民問わずあらゆる業種・分野においてWebアプリの開発支援やWebサイト制作を行い、様々なノウハウと確かな技術力を蓄積してまいりました。サービス提供者であるお客様の要望と、エンドユーザが快適に利用できる質の高いサービスを提供いたします。
        </Typography>
        <Stack className="my-10">
          <Grid
            container
            spacing={{
              xs: 1,
              sm: 1.5,
              md: 2.5,
            }}
            alignItems="stretch"
          >
            <Grid item xs={12} sm={6}>
              <Box className="bg-[#ECE2C5] w-full py-4 text-center font-light">
                官公庁情報サイトの開発、運用・保守
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box className="bg-[#ECE2C5] w-full py-4 text-center font-light">
                企業ECシステム開発
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box className="bg-[#ECE2C5] w-full py-4 text-center font-light">
                企業CMS導入、開発
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box className="bg-[#ECE2C5] w-full py-4 text-center font-light">
                学術集会Webサイト制作
              </Box>
            </Grid>
          </Grid>
        </Stack>
      </Box>
    </Box>
  )
}
