import { Stack, SxProps, Theme } from '@mui/material'
import { Link } from 'react-router-dom'
import Arrow from './Arrow'
import { HTMLAttributeAnchorTarget } from 'react'

interface Props {
  text: string
  to?: string
  sx?: SxProps<Theme>
  hoverColor?: string
  arrowIcon?: boolean
  target?: HTMLAttributeAnchorTarget
}
export default function LinkButton({
  to = '#',
  text,
  sx,
  hoverColor,
  arrowIcon,
  target,
}: Props) {
  return (
    <Stack
      className="flex-row justify-between items-center gap-4 text-sm overflow-hidden"
      sx={{
        transition: 'all 500ms',
        ...sx,
        '&:hover': {
          color: hoverColor || '#D86357',
          svg: {
            animation: 'right 0.8s ease-in infinite',
          },
          i: {
            right: 0,
            transform: 'translateX(100%)',
          },
        },
      }}
    >
      <Link
        target={target}
        to={to}
        className="w-full h-full flex items-center gap-2 relative py-1.5 px-2"
      >
        <Stack
          component="i"
          className="absolute right-[100%] h-full w-full opacity-80"
          sx={{
            transition: 'all 500ms',
            backgroundImage: (theme) =>
              `linear-gradient(to right,transparent,white ,transparent)`,
          }}
        ></Stack>
        <span className="flex-1 min-w-[60px] lg:min-w-[80px] text-center font-normal text-sm lg:text-base">
          {text}
        </span>
        {arrowIcon && <Arrow />}
      </Link>
    </Stack>
  )
}
