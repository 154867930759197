import { Box, Stack } from '@mui/material'
import React from 'react'

export default function Vision() {
  return (
    <div className="bg-[#1D5DBA] py-10 bg-bottom">
      <div className="container lg:px-[10%] max-w-5xl flex flex-col items-center justify-center text-white">
        <h3 className="heading-2 min-w-[150px] text-center mb-2 text-white">
          VISION
        </h3>
        <h4>わたしたちが目指す理想</h4>
        <Stack className="my-2 flex-row">
          <Box
            component="img"
            src="/static/left-quotes.png"
            alt="left-quotes"
            className="w-8 h-8 lg:w-10 lg:h-10 object-contain"
          ></Box>
          <h4 className="font-medium text-center lg:text-xl text-lg">
            　収益と幸せなコミュニティー作りを同時に実現し、
            <br />
            拡張することで共生社会を創ります
          </h4>
          <Box
            component="img"
            src="/static/right-quotes.png"
            alt="left-quotes"
            className="w-8 h-8 lg:w-10 lg:h-10 object-contain"
          ></Box>
        </Stack>
      </div>
    </div>
  )
}
