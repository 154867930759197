import { Box } from '@mui/material'
import Charts from 'components/Culture/Charts'
import Company from 'components/Culture/Company'
import Top from 'components/Culture/Top'
import { Helmet } from 'react-helmet'
export default function Culture() {
  return (
    <Box className="pb-10">
      <Helmet>
        <title>フォーシーズンズを知る</title>
      </Helmet>
      <Top />
      <Charts />
      <Company />
    </Box>
  )
}
