import { Box, Grid, Stack, Typography } from '@mui/material'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { getInterviews } from 'app/slices/Interview'
import LinkButton from 'components/LinkButton'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'

export default function Interview() {
  const dispatch = useAppDispatch()
  const status = useAppSelector((s) => s.Interview.status)
  const data = useAppSelector((s) => s.Interview.list)
  useEffect(() => {
    if (status === 'init') dispatch(getInterviews())
  }, [status, dispatch])
  return (
    <Box className="container py-10">
      <Stack className="items-center justify-center gap-4 mb-8">
        <Typography className="heading-2">インタビュー</Typography>
        <Typography>
          多様な人、多彩な感性。仲間たちに色々と聞いてみました。
        </Typography>
      </Stack>
      <Grid
        container
        spacing={{
          xs: 2,
          sm: 2,
          md: 3,
          lg: 10,
        }}
        alignItems="stretch"
        className="px-10"
      >
        {[...data].map((v, i) => {
          return (
            <Grid item xs={12} sm={6} md={6} lg={4} key={i}>
              <Link to={'/fellow/' + v._id}>
                <Box
                  className="relative pt-[130%] bg-transparent cursor-pointer"
                  bgcolor={'primary.main'}
                >
                  <Stack className="flex-row flex-wrap absolute top-0 left-0 z-10 bg-[#CDA83Faa] text-white items-center justify-start">
                    <Typography className="py-1 px-2 text-sm lg:text-base">{`${v.author.trim()}`}</Typography>
                    <Typography className="py-1 px-2 text-sm lg:text-base">{`${v.position.trim()}`}</Typography>
                  </Stack>
                  <Box
                    component="img"
                    src={v.thumbnail}
                    loading="lazy"
                    width={'100%'}
                    height={'100%'}
                    className="absolute inset-0 object-cover"
                  ></Box>
                </Box>
              </Link>
              <Stack className="justify-center items-center my-4">
                <LinkButton
                  text="インタビューを読む"
                  arrowIcon
                  to={'/fellow/' + v._id}
                  sx={{
                    border: 'solid currentColor',
                    borderWidth: '2px 0',
                  }}
                />
              </Stack>
            </Grid>
          )
        })}
      </Grid>
    </Box>
  )
}
