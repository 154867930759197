import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { IReport, Status } from 'interfaces'
import axiosClient from 'utils/axiosClient'

const initialState: {
  data: IReport
  status: Status
} = {
  status: 'init',
  data: {
    name: '',
    date: '',
    business: {
      maintenance: '0',
      infra: '0',
      web: '0',
      other: '0',
    },
    averageAge: {
      all: {
        age: '0',
        year: '0',
      },
      SI: {
        age: '0',
        year: '0',
      },
    },
    gender: {
      man: '0',
      woman: '0',
    },
    overtime: '0',
    paidHoliday: '0',
    newbieMidway: {
      newbie: '0',
      midway: '0',
    },
    department: {
      engineer: '0',
      food: '0',
      management: '0',
      design: '0',
    },
    party: [],
  },
}

export const getReport = createAsyncThunk(
  'Report/getReport',
  async (name: string) => {
    const { data } = await axiosClient.get<IReport>('/api/report/' + name)
    return data
  }
)

const ReportSlice = createSlice({
  name: 'Report',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getReport.pending, (state) => {
      state.status = 'pending'
    })
    builder.addCase(getReport.rejected, (state) => {
      state.status = 'rejected'
    })
    builder.addCase(getReport.fulfilled, (state, { payload }) => {
      state.data = payload
      state.status = 'fulfilled'
    })
  },
})

export const ReportAction = ReportSlice.actions

export const ReportReducer = ReportSlice.reducer
