import { Box } from '@mui/material'
import Cloud from 'components/Work/Cloud'
import Develop from 'components/Work/Develop'
import Food from 'components/Work/Food'
import Infra from 'components/Work/Infra'
import Services from 'components/Work/Services'
import Top from 'components/Work/Top'
import { Helmet } from 'react-helmet'

export default function WorkPage() {
  return (
    <Box>
      <Helmet>
        <title>フォーシーズンズ | 事業内容・仕事内容</title>
      </Helmet>
      <Top />
      <Services />
      <Box className="bg-work-pattern">
        <Infra />
        <Develop />
        <Cloud />
      </Box>
      <Food />
    </Box>
  )
}
