import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IRecruit, Status } from 'interfaces'
import axiosClient from 'utils/axiosClient'

const initialState: {
  list: IRecruit[]
  status: Status
  applicationStatus: Status
} = {
  list: [],
  status: 'init',
  applicationStatus: 'init',
}

export const getRecruits = createAsyncThunk('Recruit/getRecruits', async () => {
  const { data } = await axiosClient.get<IRecruit[]>('/api/recruit')
  return data.filter((f) => f.public)
})
export const getRecruit = createAsyncThunk(
  'Recruit/getRecruit',
  async (_id: string) => {
    const { data } = await axiosClient.get<IRecruit>('/api/recruit/' + _id)
    return data
  }
)

const RecruitSlice = createSlice({
  name: 'Recruit',
  initialState,
  reducers: {
    setApplicationStatus(state, { payload }: PayloadAction<Status>) {
      state.applicationStatus = payload
    },
    deleteItem(state, { payload }: PayloadAction<string>) {
      if (state.list) {
        const index = state.list.findIndex((f) => f._id === payload)
        if (index >= 0) {
          state.list.splice(index, 1)
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getRecruits.pending, (state) => {
      state.status = 'pending'
    })
    builder.addCase(getRecruits.rejected, (state) => {
      state.status = 'rejected'
    })
    builder.addCase(getRecruits.fulfilled, (state, { payload }) => {
      state.list = payload
      state.status = 'fulfilled'
    })
    builder.addCase(getRecruit.fulfilled, (state, { payload }) => {
      const index = state.list.findIndex((f) => f._id === payload._id)
      if (index >= 0) {
        if (payload.public) state.list.splice(index, 1, payload)
        else state.list.splice(index, 1)
      } else {
        if (payload.public) state.list.push(payload)
      }
    })
  },
})

export const RecruitAction = RecruitSlice.actions

export const RecruitReducer = RecruitSlice.reducer
