import { Box, Stack, Typography } from '@mui/material'

export default function Top() {
  return (
    <Box className="h-[350px] relative">
      <Stack className="absolute inset-0">
        <Box
          component="img"
          src="/static/culture-top.png"
          alt="about-top"
          className="w-full h-full object-cover"
          sx={{
            objectPosition: 'center 35%',
          }}
        ></Box>
      </Stack>
      <Stack className="relative z-10 container h-full w-full justify-center items-center">
        <Stack className="gap-6 bg-white/40 p-10">
          <Stack className="items-center text-shadow-white">
            <Stack className="justify-center items-center">
              <Box component="img" src="/static/culture-gray.svg"></Box>
              <Typography className="font-medium" mt={1}>
                フォーシーズンズを知る
              </Typography>
            </Stack>
          </Stack>
          <Stack className="justify-center items-center text-shadow-white">
            <Typography className="font-medium">
              社員ひとりひとりが楽しみながら挑戦できる。
            </Typography>
            <Typography className="font-medium">
              そんな環境を目指し様々な取り組みを行っています。
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  )
}
