import { Box, Stack, Typography } from '@mui/material'
import LinkButton from 'components/LinkButton'

export default function Work() {
  return (
    <Box className="bg-[#ECE2C5]">
      <Stack className="container lg:flex-row py-10 relative justify-center items-center">
        <Stack className="w-[90%] mb-10 lg:mb-auto lg:w-[60%] max-w-lg justify-end items-center overflow-hidden rounded-3xl">
          <Box
            component="img"
            src="/static/home-work.png"
            alt=""
            className="object-cover h-auto w-full hover-scale"
          ></Box>
        </Stack>
        <Stack className="w-[90%] lg:w-[40%] items-center gap-10">
          <Stack className="justify-center items-center">
            <Box component="img" src="/static/work-gray.svg"></Box>
            <Typography className="font-medium" mt={1}>
              事業内容・仕事内容
            </Typography>
          </Stack>
          <Stack className="flex flex-col items-center leading-8">
            <Typography>既成概念にとらわれず価値を共創し続ける。</Typography>
            <Typography>私たちの持つ強みと事業を紹介します。</Typography>
          </Stack>
          <LinkButton
            text="事業内容・仕事内容"
            arrowIcon
            to="/work"
            sx={{
              border: 'solid currentColor',
              borderWidth: '2px 0',
            }}
          />
        </Stack>
      </Stack>
    </Box>
  )
}
