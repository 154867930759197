import { Box, Stack, Typography } from '@mui/material'
import { useEffect, useState } from 'react'

const imgs = [
  {
    id: 0,
    src: '/static/home-top01.png',
  },
  {
    id: 1,
    src: '/static/home-top02.png',
  },
  {
    id: 2,
    src: '/static/home-top03.png',
  },
]
const animateTime = 6

export default function Top() {
  const [active, setActive] = useState<number>(imgs[0].id)
  useEffect(() => {
    const timer = setInterval(() => {
      setActive((pre) => (imgs[pre + 1] ? imgs[pre + 1].id : imgs[0].id))
    }, animateTime * 1000)

    return () => {
      timer && clearInterval(timer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active])

  return (
    <Box id="home-top-slide" className="bg-[#5ABFC5] ">
      <Stack className="container py-[5%] items-end relative">
        <Stack className="lg:max-w-[70%] w-full self-end lg:pr-[30px] relative">
          <Stack className="w-full pt-[56%] relative overflow-hidden rounded-lg">
            <Box className="absolute inset-0 image-wrap">
              {imgs.map((v, i) => {
                return (
                  <Box
                    key={v.id}
                    component="img"
                    className="absolute w-full h-full object-cover"
                    sx={{
                      opacity: active === v.id ? 1 : 0,
                      transform: active === v.id ? 'scale(1)' : 'scale(1.4)',
                      transition: `all 1s linear`,
                    }}
                    src={v.src}
                    alt=""
                    loading="lazy"
                  />
                )
              })}
            </Box>
          </Stack>
          <Stack className="absolute top-[100%] lg:top-0 lg:right-0 gap-2 cursor-pointer flex-row lg:flex-col my-2 lg:my-0">
            {imgs.map((v) => {
              return (
                <Box
                  className={`dot ${active === v.id ? 'active' : ''}`}
                  key={v.id}
                  onClick={() => {
                    setActive(v.id)
                  }}
                ></Box>
              )
            })}
          </Stack>
        </Stack>
        <Stack className="container lg:absolute flex-row left-0 top-0 h-full items-center my-20 lg:my-0">
          <Stack className="gap-4">
            <img
              className="w-full max-w-sm"
              src="/static/home-top-text.svg"
              alt="text"
            />
            <Typography className="text-white leading-7">
              私たちは社会に貢献することで自分たちのホームコミュニティだけでなく、
              <br />
              社会全体が「幸せ」な状態であることを目指します。
              <br />
              同じ目標を目指して、楽しみながら成長し幸せを分かち合える。
              <br />
              そんな仲間を求めています。
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  )
}
