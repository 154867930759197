import { Box, Grid, Stack, Typography } from '@mui/material'

export default function Cloud() {
  return (
    <Box className="container py-10">
      <Stack className="items-center justify-center gap-4 mb-8">
        <Typography className="heading-2" id="SI事業部／クラウド">
          SI事業部／クラウド
        </Typography>
        <Typography textAlign="center">
          コンサルティングから実施まで、クラウド導入をトータルにサポート
          <br />
          運用や管理の手間を軽減し、業務効率化とコスト削減に貢献
        </Typography>
      </Stack>
      <Typography className="font-light">
        パブリッククラウドやMicrosoft
        365などのクラウドサービスを活用し、お客様の課題解決に最適なクラウド環境をご提案します。Microsoft
        CSP（クラウドソリューションプロバイダー）に認定されたサポート力で、ご希望の内容や予算に合わせた導入支援からカスタマイズ、システム開発まで柔軟に対応いたします。
      </Typography>
      <Box className="lg:px-10 p-4 my-10">
        <Grid
          container
          spacing={{
            xs: 1,
            sm: 1.5,
            md: 2.5,
          }}
          justifyContent="space-evenly"
          alignItems="stretch"
        >
          <Grid item xs={12} md={3}>
            <Stack
              className="items-center lg:items-start"
              sx={{
                border: '1px solid #CDA83F',
                px: 2,
                height: '100%',
              }}
            >
              <Typography
                className="text-[#CDA83F] font-medium text-lg text-center py-4 w-full"
                sx={{
                  borderBottom: '2px dotted #CDA83F',
                }}
              >
                Microsoft 365
              </Typography>
              <Typography className="text-sm md:p-2 p-1 leading-7 w-full">
                業務効率化やテレワーク環境の早期整備実現を支援いたします。
              </Typography>
              <Stack className="p-4 flex-1 justify-center items-center w-full">
                <Box
                  component="img"
                  src="/static/ms.png"
                  alt="ms"
                  className="w-full max-w-xs max-h-[60px] object-contain"
                ></Box>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} md={3}>
            <Stack
              className="items-center lg:items-start"
              sx={{
                border: '1px solid #CDA83F',
                px: 2,
                height: '100%',
              }}
            >
              <Typography
                className="text-[#CDA83F] font-medium text-lg text-center py-4 w-full"
                sx={{
                  borderBottom: '2px dotted #CDA83F',
                }}
              >
                AWS
              </Typography>
              <Typography className="text-sm md:p-2 p-1 leading-7 w-full">
                Amazon Web
                Servicesを活用したシステムの開発を、ワンストップで提供いたします。
              </Typography>
              <Stack className="p-4 flex-1 justify-center items-center w-full">
                <Box
                  component="img"
                  src="/static/amazon.png"
                  alt="amazon"
                  className="w-full max-w-xs max-h-[80px] object-contain"
                ></Box>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} md={3}>
            <Stack
              className="items-center lg:items-start"
              sx={{
                border: '1px solid #CDA83F',
                px: 2,
                height: '100%',
              }}
            >
              <Typography
                className="text-[#CDA83F] font-medium text-lg text-center py-4 w-full"
                sx={{
                  borderBottom: '2px dotted #CDA83F',
                }}
              >
                Microsoft Azure
              </Typography>
              <Typography className="text-sm md:p-2 p-1 leading-7 w-full">
                従来システムからの移行など、Azureの活用をトータルで支援いたします。
              </Typography>
              <Stack className="p-4 flex-1 justify-center items-center w-full">
                <Box
                  component="img"
                  src="/static/azure.png"
                  alt="azure"
                  className="w-full max-w-xs max-h-[80px] object-contain"
                ></Box>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} md={3}>
            <Stack
              className="items-center lg:items-start"
              sx={{
                border: '1px solid #CDA83F',
                px: 2,
                height: '100%',
              }}
            >
              <Typography
                className="text-[#CDA83F] font-medium text-lg text-center py-4 w-full"
                sx={{
                  borderBottom: '2px dotted #CDA83F',
                }}
              >
                Zoom
              </Typography>
              <Typography className="text-sm md:p-2 p-1 leading-7 w-full">
                多様なコミュニケーションが可能なクラウド型Web会議の導入を支援いたします。
              </Typography>
              <Stack className="p-4 flex-1 justify-center items-center w-full">
                <Box
                  component="img"
                  src="/static/zoom.png"
                  alt="zoom"
                  className="w-full max-w-xs max-h-[50px] object-contain"
                ></Box>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
