import { createTheme, ThemeProvider } from '@mui/material'
import { jaJP } from '@mui/material/locale'
import { useMemo } from 'react'

interface Props {
  children: any
}

export default function MaterialProvider({ children }: Props) {
  const theme = useMemo(() => {
    return createTheme(
      {
        breakpoints: {
          values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1200,
            xl: 1536,
          },
        },
        typography: {
          fontFamily: [
            'Noto Sans JP',
            'Noto Serif JP',
            'Zen Old Mincho',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
          ].join(','),
        },
        palette: {
          primary: {
            main: '#1D5DBA',
          },
          secondary: {
            main: '#CDA83F',
            contrastText: '#fff',
          },
          error: {
            main: '#e53935',
          },
          info: {
            main: '#1976d2',
          },
          warning: {
            main: '#f57c00',
            contrastText: '#fff',
          },
          success: {
            main: '#388e3c',
          },
        },
        mixins: {
          toolbar: {
            height: 50,
          },
        },
        components: {
          MuiFormLabel: {
            styleOverrides: {
              asterisk: {
                color: '#db3131',
                '&$error': {
                  color: '#db3131',
                },
              },
            },
          },
        },
      },
      jaJP
    )
  }, [])
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}
