import { Box, Grid, Typography } from '@mui/material'
import { useAppDispatch } from 'app/hooks'
import { UIAction } from 'app/slices/UI'

export default function Category() {
  const dispatch = useAppDispatch()
  return (
    <Box className="container py-10">
      <Grid
        container
        spacing={{
          xs: 1,
          sm: 2,
          md: 3,
          lg: 4,
          xl: 6,
        }}
        alignItems="stretch"
      >
        <Grid item xs={12} sm={6}>
          <Box
            className="h-[250px] rounded-3xl overflow-hidden relative cursor-pointer"
            onClick={() => {
              dispatch(UIAction.setTarget('#新卒採用'))
            }}
          >
            <Box
              component="img"
              src="/static/f01.png"
              alt="f01"
              className="w-full object-cover h-full"
            ></Box>
            <Typography
              component="span"
              className="absolute bottom-0 left-0 z-10 bg-white/80 px-10 py-2 w-[50%] text-center text-[#1D5DBA]"
            >
              新卒採用
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box
            className="h-[250px] rounded-3xl overflow-hidden relative cursor-pointer"
            onClick={() => {
              dispatch(UIAction.setTarget('#キャリア採用'))
            }}
          >
            <Box
              component="img"
              src="/static/f02.png"
              alt="f02"
              className="w-full object-cover h-full"
            ></Box>
            <Typography
              component="span"
              className="absolute bottom-0 left-0 z-10 bg-white/80 px-10 py-2 w-[50%] text-center text-[#1D5DBA]"
            >
              キャリア採用
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
