import { Box, Grid, Paper, Skeleton, Stack, Typography } from '@mui/material'
import { useAppSelector, useAppDispatch } from 'app/hooks'
import { getReport } from 'app/slices/Report'
import { PieChart } from 'components/PieChart'
import { format } from 'date-fns'
import { GiPineTree, GiTreeBranch } from 'react-icons/gi'
import { useEffect, useMemo } from 'react'

export default function Charts() {
  const status = useAppSelector((s) => s.Report.status)
  const data = useAppSelector((s) => s.Report.data)
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (status === 'init') dispatch(getReport('4s'))
  }, [status, dispatch])
  const values = useMemo(() => {
    return {
      department: [
        {
          label: 'エンジニア',
          value: data.department.engineer,
          color: '#1D5DBA',
        },
        {
          label: '飲食',
          value: data.department.food,
          color: '#31AFB6',
        },
        {
          label: '管理',
          value: data.department.management,
          color: '#D1E5F8',
        },
        {
          label: 'デザイン',
          value: data.department.design,
          color: '#FFDA1A',
        },
      ],
      business: [
        {
          label: 'インフラ開発',
          value: data.business.infra,
          color: '#1D5DBA',
        },
        {
          label: '運用保守',
          value: data.business.maintenance,
          color: '#31AFB6',
        },
        {
          label: 'Web開発',
          value: data.business.web,
          color: '#e12212',
        },
        {
          label: 'その他',
          value: data.business.other,
          color: '#FFDA1A',
        },
      ],
    }
  }, [
    data.business.infra,
    data.business.maintenance,
    data.business.other,
    data.business.web,
    data.department.design,
    data.department.engineer,
    data.department.food,
    data.department.management,
  ])
  return data.name ? (
    <Box className="bg-news-pattern">
      <Box className="container py-10">
        <Stack className="items-center justify-center gap-4 mb-8">
          <Typography className="heading-2">図でわかる4S</Typography>
          <Typography>
            {format(new Date(data.date), 'yyyy年MM月時点')}
          </Typography>
        </Stack>
        <Grid
          container
          spacing={{
            xs: 2,
            md: 3,
            lg: 5,
          }}
          alignItems="stretch"
        >
          <Grid item xs={12}>
            <Paper elevation={3} className="h-full">
              <Stack className="lg:flex-row items-center justify-center p-5 pt-16 lg:pt-20 pb-8 relative">
                <p className="absolute top-8 -translate-y-1/2 left-0 bg-[#1D5DBA] text-white py-1 px-4">
                  事業ごとの受注割合
                </p>
                <div className="flex-1 flex flex-wrap max-w-sm">
                  {values.business.map((v) => {
                    return (
                      <div
                        key={v.label}
                        className="flex items-center justify-end gap-2 w-1/2 my-4 text-sm lg:text-base"
                      >
                        <span
                          className="w-4 h-4"
                          style={{
                            background: v.color,
                          }}
                        ></span>
                        <p className="flex-1 max-w-[150px]">
                          {v.label}
                          {' ' + v.value}%
                        </p>
                      </div>
                    )
                  })}
                </div>
                <div className="w-[300px]">
                  <PieChart
                    datasets={[
                      {
                        data: values.business.map((v) => Number(v.value)),
                        backgroundColor: values.business.map((v) => v.color),
                        borderColor: values.business.map((v) => '#fff'),
                        borderWidth: 1,
                        borderAlign: 'center',
                      },
                    ]}
                    labels={values.business.map((v) => v.label)}
                  />
                </div>
              </Stack>
            </Paper>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Paper elevation={3} className="h-full">
              <Stack className="items-center p-5 pt-16 lg:pt-20 pb-8 relative h-full justify-center">
                <Typography className="absolute top-8 -translate-y-1/2 left-0 bg-[#1D5DBA] text-white py-1 px-4">
                  平均年齢・勤続年数
                </Typography>
                <Stack className="w-full gap-10">
                  <Stack className="flex-row items-center justify-center gap-4 lg:gap-10">
                    <Typography className="lg:text-lg font-normal w-16 lg:w-20 ">
                      全社
                    </Typography>
                    <Typography className="text-[#31AFB6] text-4xl lg:text-5xl font-medium">
                      <span>{data.averageAge.all.age}</span>
                      <span className="text-lg">歳</span>
                      <span>・{data.averageAge.all.year}</span>
                      <span className="text-lg">年</span>
                    </Typography>
                  </Stack>
                  <Stack className="flex-row items-center justify-center gap-4 lg:gap-10">
                    <Typography className="lg:text-lg font-normal w-16 lg:w-20 ">
                      SI事業部
                    </Typography>
                    <Typography className="text-[#31AFB6] text-4xl lg:text-5xl font-medium">
                      <span>{data.averageAge.SI.age}</span>
                      <span className="text-lg">歳</span>
                      <span>・{data.averageAge.SI.year}</span>
                      <span className="text-lg">年</span>
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Paper>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Paper elevation={3} className="h-full">
              <Stack className="items-center p-5 pt-16 lg:pt-20 pb-8 relative h-full">
                <Typography className="absolute top-8 -translate-y-1/2 left-0 bg-[#1D5DBA] text-white py-1 px-4">
                  男女比
                </Typography>
                <Stack className="w-full flex-row">
                  <Stack className="flex-1 items-center justify-center gap-2">
                    <Stack className="w-20">
                      <img src="/static/man-icon.png" alt="man" />
                    </Stack>
                    <Typography className="font-normal">男性</Typography>
                  </Stack>
                  <Stack className="flex-1 items-center justify-center gap-2">
                    <Stack className="w-20">
                      <img src="/static/woman-icon.png" alt="man" />
                    </Stack>
                    <Typography className="font-normal">女性</Typography>
                  </Stack>
                </Stack>
                <Stack className="w-full flex-row justify-center text-[#31AFB6] text-5xl font-medium">
                  <p className="flex-1 text-center">
                    <span className="relative mr-0.5">
                      {data.gender.man}
                      <span className="text-base font-normal -translate-y-1/2 absolute bottom-0 left-[100%]">
                        %
                      </span>
                    </span>
                  </p>
                  <span>:</span>
                  <p className="flex-1 text-center">
                    <span className="relative mr-0.5">
                      {data.gender.woman}
                      <span className="text-base font-normal -translate-y-1/2 absolute bottom-0 left-[100%]">
                        %
                      </span>
                    </span>
                  </p>
                </Stack>
              </Stack>
            </Paper>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Paper elevation={3} className="h-full">
              <Stack className="items-center p-5 pt-16 lg:pt-20 pb-8 relative">
                <Typography className="absolute top-8 -translate-y-1/2 left-0 bg-[#1D5DBA] text-white py-1 px-4">
                  残業時間
                </Typography>
                <Stack className="flex-1 flex flex-col items-center justify-center">
                  <Stack className="w-32">
                    <img src="/static/overtime.png" alt="overtime" />
                  </Stack>
                  <Stack className="flex-row items-center justify-center gap-4 mt-4">
                    <Typography className="text-4s-color text-lg font-normal">
                      SI事業部
                    </Typography>
                    <Typography className="text-[#31AFB6] text-5xl font-medium">
                      <span>{data.overtime}</span>
                      <span className="text-lg">時間</span>
                    </Typography>
                  </Stack>
                  <Typography className="text-sm font-light mt-2">
                    ※月基本稼働時間170時間を超えた時間
                  </Typography>
                </Stack>
              </Stack>
            </Paper>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Paper elevation={3} className="h-full">
              <Stack className="items-center p-5 pt-16 lg:pt-20 pb-8 relative">
                <Typography className="absolute top-8 -translate-y-1/2 left-0 bg-[#1D5DBA] text-white py-1 px-4">
                  有休消化率
                </Typography>
                <Stack className="flex-1 flex flex-col items-center justify-center">
                  <Stack className="w-32">
                    <img src="/static/holiday.png" alt="holiday" />
                  </Stack>
                  <Stack className="flex-row items-center justify-center gap-4 mt-4">
                    <Typography className="text-4s-color text-lg font-normal">
                      全社
                    </Typography>
                    <Typography className="text-[#31AFB6] text-5xl font-medium">
                      <span>{data.paidHoliday}</span>
                      <span className="text-lg">%</span>
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Paper>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Paper elevation={3} className="h-full">
              <Stack className="items-center p-5 pt-16 lg:pt-20 pb-8 relative">
                <Typography className="absolute top-8 -translate-y-1/2 left-0 bg-[#1D5DBA] text-white py-1 px-4">
                  新卒中途内訳
                </Typography>
                <Stack className="w-full flex-row">
                  <Stack className="flex-1 items-center justify-center gap-2">
                    <Stack className="rounded-full bg-neutral-200 p-8 text-7xl text-emerald-700">
                      <GiPineTree />
                    </Stack>
                    <Typography className="font-normal">中途</Typography>
                  </Stack>
                  <Stack className="flex-1 items-center justify-center gap-2">
                    <Stack className="rounded-full bg-neutral-200 p-8 text-7xl text-emerald-700">
                      <GiTreeBranch />
                    </Stack>
                    <Typography className="font-normal">新卒</Typography>
                  </Stack>
                </Stack>
                <Stack className="w-full flex-row justify-center text-[#31AFB6] text-5xl font-medium">
                  <p className="flex-1 text-center">
                    <span className="relative mr-0.5">
                      {data.newbieMidway.midway}
                      <span className="text-base font-normal -translate-y-1/2 absolute bottom-0 left-[100%]">
                        %
                      </span>
                    </span>
                  </p>
                  <span>:</span>
                  <p className="flex-1 text-center">
                    <span className="relative mr-0.5">
                      {data.newbieMidway.newbie}
                      <span className="text-base font-normal -translate-y-1/2 absolute bottom-0 left-[100%]">
                        %
                      </span>
                    </span>
                  </p>
                </Stack>
              </Stack>
            </Paper>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Paper elevation={3} className="h-full">
              <Stack className="lg:flex-row items-center justify-center p-5 pt-16 lg:pt-20 pb-8 relative h-full gap-4 ">
                <p className="absolute top-8 -translate-y-1/2 left-0 bg-[#1D5DBA] text-white py-1 px-4">
                  部署別従業員数
                </p>
                <Stack className="lg:flex-col flex-row flex-wrap">
                  {values.department.map((v) => {
                    return (
                      <Stack
                        key={v.label}
                        className="flex-row items-center justify-end gap-2 lg:w-full my-2 w-1/2"
                      >
                        <span
                          className="w-4 h-4"
                          style={{
                            background: v.color,
                          }}
                        ></span>
                        <span className="flex-1 text-sm">
                          {v.label}
                          {' ' + v.value}%
                        </span>
                      </Stack>
                    )
                  })}
                </Stack>
                <Box className="w-[250px]">
                  <PieChart
                    datasets={[
                      {
                        data: values.department.map((v) => Number(v.value)),
                        backgroundColor: values.department.map((v) => v.color),
                        borderColor: values.department.map(() => '#fff'),
                        borderWidth: 1,
                        borderAlign: 'center',
                      },
                    ]}
                    labels={values.department.map((v) => v.label)}
                  />
                </Box>
              </Stack>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Box>
  ) : (
    <Stack className="container justify-center items-center py-10 gap-4">
      <Skeleton variant="rounded" className="w-full max-w-md" height={40} />
      <Skeleton variant="rounded" className="w-full max-w-md" height={300} />
    </Stack>
  )
}
