import { Box, Stack, Typography } from '@mui/material'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { getSlides } from 'app/slices/Slide'
import LinkButton from 'components/LinkButton'
import React, { useEffect, useRef, useState } from 'react'
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai'

interface Props {
  heading?: boolean
}

export default function FellowSlide({ heading = true }: Props) {
  const dispatch = useAppDispatch()
  const slides = useAppSelector((s) => s.Slide.list)
  const status = useAppSelector((s) => s.News.status)
  const [data, setData] = useState(slides)
  const [move, setMove] = useState('')
  const parent = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (status === 'init') {
      dispatch(getSlides())
    }
  }, [dispatch, status])
  useEffect(() => {
    if (slides.length) {
      setData(slides)
    }
  }, [dispatch, slides])
  useEffect(() => {
    if (move) {
      const tmp = [...data]
      setTimeout(() => {
        if (move === 'left') {
          const last = tmp.pop()
          if (!last) return
          setData([last, ...tmp])
        } else if (move === 'right') {
          const first = tmp.shift()
          if (!first) return
          setData([...tmp, first])
        }
        setMove('')
        if (parent.current) parent.current.focus()
      }, 620)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [move])

  return (
    <Stack className="my-10">
      <input type="hidden" ref={parent} />
      {heading && (
        <Stack className="container">
          <Stack className="justify-center items-center">
            <Box component="img" src="/static/fellow-gray.svg"></Box>
            <Typography className="font-medium" mt={1}>
              仲間たち
            </Typography>
          </Stack>
          <Stack className="flex flex-col gap-2 justify-center items-center my-10">
            <Typography>多様な人、多彩な感性。</Typography>
            <Typography>当社で働く仲間たちに色々と聞いてみました。</Typography>
          </Stack>
        </Stack>
      )}
      <Stack className="fellow">
        <Box
          className="action-btn left"
          onClick={() => {
            setMove('left')
          }}
        >
          <AiOutlineLeft />
        </Box>
        <Stack
          className="slides flex-row"
          data-move={move}
          style={{
            animationDuration: slides.length * 12 + 's',
            animationDelay: -slides.length * 12 + 's',
          }}
        >
          {data.map((slide, i) => {
            return (
              <React.Fragment key={i}>
                <Box
                  component="div"
                  sx={{
                    order: i,
                  }}
                >
                  <Box component="img" src={slide.url} alt=""></Box>
                </Box>
                <Box
                  component="div"
                  sx={{
                    order: slides.length + i,
                  }}
                >
                  <Box component="img" src={slide.url} alt=""></Box>
                </Box>
              </React.Fragment>
            )
          })}
        </Stack>
        <Box
          className="action-btn right"
          onClick={(e) => {
            setMove('right')
          }}
        >
          <AiOutlineRight />
        </Box>
      </Stack>
      {heading && (
        <Stack className="flex-row justify-center mt-10">
          <LinkButton
            text="仲間たち"
            arrowIcon
            to="/fellow"
            sx={{
              border: 'solid currentColor',
              borderWidth: '2px 0',
              width: 200,
            }}
          />
        </Stack>
      )}
    </Stack>
  )
}
