import { Box, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import { AiFillCaretRight } from 'react-icons/ai'

export default function Infra() {
  return (
    <Box className="container py-10">
      <Stack className="items-center justify-center gap-4 mb-8">
        <Typography className="heading-2" id="SI事業部／インフラ">
          SI事業部／インフラ
        </Typography>
        <Typography textAlign="center">
          高い技術力と豊富な開発経験を活かし、
          <br />
          システム設計から構築、運用までをワンストップで支援
        </Typography>
      </Stack>
      <Typography className="font-light">
        大規模なシステム開発、ネットワーク運用で培った確かな技術力で、お客様の業務課題に最適なシステムを構築するSIサービスを提供します。要件定義や詳細設計から開発、運用工程までを一貫した体制で受託、お客様の課題解決に向けてワンストップで支援いたします。
      </Typography>
      <Stack className="w-full md:flex-row justify-between items-center my-10">
        {steps.map((v, i) => {
          return (
            <React.Fragment key={i}>
              {i !== 0 && (
                <AiFillCaretRight className="text-2xl text-[#CDA83F] rotate-90 md:rotate-0" />
              )}
              <Typography
                sx={{
                  border: '2px solid #CDA83F',
                }}
                className="flex items-center justify-center text-4s-color font-normal w-[130px] md:h-[130px] h-[60px]"
              >
                {v.text}
              </Typography>
            </React.Fragment>
          )
        })}
      </Stack>
      <Typography className="heading-3">主な実績</Typography>
      <Typography className="font-light my-4">
        中央省庁などの公共サービスをはじめとしたネットワーク基盤構築や大型システムのリプレース、セキュリティ基盤の新規構築、仮想サーバの基盤構築など、あらゆる業種・分野において、高いレベルでの業務を実施してまいりました。
      </Typography>
      <Stack className="my-10">
        <Grid
          container
          spacing={{
            xs: 1,
            sm: 1.5,
            md: 2.5,
          }}
          alignItems="stretch"
        >
          <Grid item xs={12} sm={6}>
            <Box className="bg-[#ECE2C5] w-full py-4 text-center font-light">
              官公庁ネットワーク基盤構築
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box className="bg-[#ECE2C5] w-full py-4 text-center font-light">
              官公庁ネットワーク基盤リプレース
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box className="bg-[#ECE2C5] w-full py-4 text-center font-light">
              官公庁業務基盤システムの設計構築
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box className="bg-[#ECE2C5] w-full py-4 text-center font-light">
              官公庁業務システムのサーバ設計構築
            </Box>
          </Grid>
        </Grid>
      </Stack>
    </Box>
  )
}
const steps = [
  {
    text: '企画',
  },
  {
    text: '要件定義',
  },
  {
    text: '設計',
  },
  {
    text: '開発',
  },
  {
    text: 'テスト',
  },
  {
    text: '運用・保守',
  },
]
