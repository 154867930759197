import LinkButton from 'components/LinkButton'
import ChangeTheLifeStyle from './ChangeTheLifeStyle'
import { Box, Stack, Typography } from '@mui/material'

export default function About() {
  return (
    <Box className="bg-[#ECE2C5]">
      <Stack className="container flex-row py-10 relative">
        <Stack className="flex-1 items-center gap-5 mb-10 lg:mb-0">
          <Stack className="justify-center items-center">
            <Box component="img" src="/static/about-gray.svg"></Box>
            <Typography className="font-medium" mt={1}>
              フォーシーズンズの想い
            </Typography>
          </Stack>
          <Stack className="items-center leading-8">
            <p>事業やサービスはあくまで手段。</p>
            <p>そもそも私たちはなんのためにビジネスをするのか。</p>
            <p>サービスを通してどんな社会を作りたいのか。</p>
            <p>そのために私たちが大切にしている想い。</p>
          </Stack>
          <LinkButton
            text="フォーシーズンズの想い"
            arrowIcon
            to="/about"
            sx={{
              border: 'solid currentColor',
              borderWidth: '2px 0',
            }}
          />
        </Stack>
        <div className="bg-clip-path--01 hidden lg:flex">
          <svg>
            <clipPath id="clip-path-01">
              <path d="M367.103 0.985756L367.102 0.985825C180.642 30.9034 33.1176 176.635 1.8021 362.49C-1.14118 374.407 1.02797 384.183 7.08043 390.981C13.1254 397.771 22.9655 401.5 35.1868 401.5H348.367C377.574 401.5 401.5 377.625 401.5 348.38V35.8881C401.5 23.8793 398.173 14.3127 392.175 8.15897C386.165 1.99199 377.538 -0.679176 367.103 0.985756Z" />
            </clipPath>
          </svg>
        </div>
        <div className="absolute bottom-2 left-1/2 -translate-x-1/2 w-full max-w-5xl opacity-10 pl-1 pr-2">
          <ChangeTheLifeStyle />
        </div>
      </Stack>
    </Box>
  )
}
