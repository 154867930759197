import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { INews, Status } from 'interfaces'
import axiosClient from 'utils/axiosClient'

const initialState: {
  list: INews[]
  status: Status
} = {
  list: [],
  status: 'init',
}

export const getNewsList = createAsyncThunk('News/getNewsList', async () => {
  const { data } = await axiosClient.get<INews[]>('/api/news')
  return data.filter((f) => new Date(f.date).getTime() < new Date().getTime())
})
export const getNews = createAsyncThunk('News/getNews', async (_id: string) => {
  const { data } = await axiosClient.get<INews>('/api/news/' + _id)
  return data
})

const NewsSlice = createSlice({
  name: 'News',
  initialState,
  reducers: {
    deleteItem(state, { payload }: PayloadAction<string>) {
      if (state.list) {
        const index = state.list.findIndex((f) => f._id === payload)
        if (index >= 0) {
          state.list.splice(index, 1)
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNewsList.pending, (state) => {
      state.status = 'pending'
    })
    builder.addCase(getNewsList.rejected, (state) => {
      state.status = 'rejected'
    })
    builder.addCase(getNewsList.fulfilled, (state, { payload }) => {
      state.list = payload
      state.status = 'fulfilled'
    })
    builder.addCase(getNews.fulfilled, (state, { payload }) => {
      const index = state.list.findIndex((f) => f._id === payload._id)
      if (index >= 0) {
        state.list.splice(index, 1, payload)
      } else {
        state.list.push(payload)
      }
    })
  },
})

export const NewsAction = NewsSlice.actions

export const NewsReducer = NewsSlice.reducer
