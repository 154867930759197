import { Box, Stack } from '@mui/material'
import { Link } from 'react-router-dom'
import LinkButton from './LinkButton'
import { menus } from './Header'

export default function Footer() {
  return (
    <footer>
      <Stack className="lg:flex-row">
        <Box className="relative flex-1 h-[300px]">
          <Box
            component="img"
            src="/static/f01.png"
            alt="f01"
            className="w-full h-full object-cover"
          />
          <Stack className="absolute z-10 inset-0 justify-center items-center">
            <Stack
              className="p-10 bg-white/60"
              sx={{ border: '1px solid #eee7' }}
            >
              <LinkButton
                to="/recruit/新卒採用"
                text="新卒採用"
                arrowIcon
                sx={{
                  border: 'solid currentColor',
                  borderWidth: '2px 0',
                  width: 200,
                  bgcolor: '#fff6',
                  color: '#444',
                }}
              />
            </Stack>
          </Stack>
        </Box>
        <Box className="relative flex-1 h-[300px]">
          <Box
            component="img"
            src="/static/f02.png"
            alt="f02"
            className="w-full h-full object-cover"
          />
          <Stack className="absolute z-10 inset-0 justify-center items-center">
            <Stack
              className="p-10 bg-white/60"
              sx={{ border: '1px solid #eee7' }}
            >
              <LinkButton
                to="/recruit/キャリア採用"
                text="キャリア採用"
                arrowIcon
                sx={{
                  border: 'solid currentColor',
                  borderWidth: '2px 0',
                  width: 200,
                  bgcolor: '#fff6',
                  color: '#444',
                }}
              />
            </Stack>
          </Stack>
        </Box>
      </Stack>
      <Stack className="bg-[#393F4E] text-white text-sm pt-4">
        <Stack className="container flex-row flex-wrap items-center justify-between underline gap-4 mb-8">
          <Link to="/" className="h-20 w-full lg:w-auto">
            <Box
              component="img"
              src={`/static/footer-logo.svg`}
              alt="logo"
              className="h-full"
            />
          </Link>
          {menus.map((m, i) => {
            return (
              <Link key={i} className="w-[45%] lg:w-auto" to={'/' + m.to}>
                {m.text}
              </Link>
            )
          })}
        </Stack>
        <div className="container flex justify-center lg:justify-start underline gap-4 my-4">
          <Link to="https://www.0004s.com/" target="_blank" className="flex">
            <span>企業サイト</span>
            <svg
              width="12"
              height="12"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M2.308 0v9.692H12V0H2.308zm8.76 8.76H3.24V2.61h7.828v6.15z" />
              <path d="M.932 11.068v-8.76H0V12h9.692v-.932H.932z" />
            </svg>
          </Link>
          <Link
            to="https://goo.gl/maps/TpjzKt3JzueTWRtt5"
            target="_blank"
            className=""
          >
            アクセス
          </Link>
          <Link
            to="https://www.0004s.com/pdf/privacy.pdf"
            target="_blank"
            className=""
          >
            プライバシーポリシー
          </Link>
        </div>
        <div className="container">
          <p className="text-xs text-center lg:text-right decoration-slate-100 underline-offset-4 py-2">
            <em>
              Copyright © {new Date().getFullYear()} Four Seasons inc. All
              Rights Reserved.
            </em>
          </p>
        </div>
      </Stack>
    </footer>
  )
}
