import { Box, Stack, Typography } from '@mui/material'
import { useAppSelector } from 'app/hooks'
import LinkButton from 'components/LinkButton'
import Top from 'components/Recruit/Top'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export default function ApplicationComplete() {
  const nav = useNavigate()
  const applicationStatus = useAppSelector((s) => s.Recruit.applicationStatus)
  useEffect(() => {
    if (applicationStatus !== 'completed') nav('/')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationStatus])

  return (
    <Box>
      <Top />
      <Stack className="container py-10 items-center justify-center gap-4">
        <Typography className="text-xl font-medium">
          ご応募ありがとうございます
        </Typography>
        <Typography className="text-center leading-7">
          この度は、フォーシーズンズ株式会社の求人にご応募いただきまして、誠にありがとうございます。
          <br />
          内容を確認いたしまして、7営業日以内に担当者よりご連絡差し上げますので、少々お待ちくださいませ。
          ご不明な点があれば本メールに直接ご返信ください。
        </Typography>
        <LinkButton
          text="ホーム戻る"
          to="/"
          arrowIcon
          sx={{
            border: 'solid currentColor',
            borderWidth: '2px 0',
            width: 200,
          }}
        />
      </Stack>
    </Box>
  )
}
