import About from 'components/Home/About'
import Culture from 'components/Home/Culture'
import FellowSlide from 'components/Home/FellowSlide'
import FollowUs from 'components/Home/FollowUs'
import News from 'components/Home/News'
import Recruit from 'components/Home/Recruit'
import Top from 'components/Home/Top'
import Work from 'components/Home/Work'

export default function Home() {
  return (
    <>
      <Top />
      <News />
      <About />
      <Culture />
      <Work />
      <FellowSlide />
      <FollowUs />
      <Recruit />
    </>
  )
}
