import React from 'react'
import Committee from './Committee'
import Welfare from './Welfare'
import Report from './Report'
import { Box, Stack, Typography } from '@mui/material'

export default function Company() {
  return (
    <Box className="bg-news-pattern">
      <Box className="container py-10">
        <Stack className="items-center justify-center gap-4 mb-8">
          <Typography className="heading-2">文化・制度・福利厚生</Typography>
          <Typography>
            社員ひとりひとりが楽しみながら挑戦を続けられるような環境づくり。
          </Typography>
        </Stack>
        <Welfare />
        <Committee />
        <Report />
      </Box>
    </Box>
  )
}
