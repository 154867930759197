import React, { useEffect, useState } from 'react'
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  ChartDataset,
  ChartOptions,
} from 'chart.js'
import { Pie } from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels'

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels)

interface Props {
  tooltipTitle?: string
  labels: string[]
  datasets: ChartDataset<'pie', number[]>[]
  options?: ChartOptions<'pie'>
  textSizeRate?: number
  showLegend?: boolean
}

export function PieChart({
  tooltipTitle,
  datasets,
  labels,
  options,
  textSizeRate = 1.5,
  showLegend = false,
}: Props) {
  const [fontSize, setFontSize] = useState(12)
  useEffect(() => {
    window && setFontSize(window.innerWidth * 0.008)
  }, [])

  return (
    <Pie
      data={{
        labels,
        datasets,
      }}
      options={{
        responsive: true,
        plugins: {
          legend: {
            display: showLegend,
            labels: {
              font: {
                size: 16,
              },
            },
            onHover: (evt, item, legend) => {
              if (datasets[0] && datasets[0].backgroundColor) {
                ;(datasets[0].backgroundColor as string[]).forEach(
                  (color, index, colors) => {
                    colors[index] =
                      index === item.index || color.length === 9
                        ? color
                        : color + '33'
                  }
                )
              }
              legend.chart.update()
            },
            onLeave: (evt, item, legend) => {
              if (datasets[0] && datasets[0].backgroundColor) {
                ;(datasets[0].backgroundColor as string[]).forEach(
                  (color, index, colors) => {
                    colors[index] =
                      color.length === 9 ? color.slice(0, -2) : color
                  }
                )
              }
              legend.chart.update()
            },
            position: 'left',
            onClick(e, legendItem, legend) {
              return true
            },
          },
          datalabels: {
            display: true,
            color: '#fff',
            font: {
              size: fontSize * textSizeRate > 10 ? fontSize * textSizeRate : 10,
            },
            align: 'start',
            anchor: 'end',
            textStrokeWidth: 2,
            textStrokeColor: '#aaa',
            textShadowColor: '#aaa',
            formatter(
              value,
              {
                dataIndex,
                chart: {
                  data: { labels },
                },
              }
            ) {
              const n = (labels as any)[dataIndex].length
              if (value < 10) return value + '%'
              return `${(labels as any)[dataIndex]}\n${
                n >= (value + '%').length
                  ? new Array(Math.ceil((n - 1) / 2)).fill('').join('　')
                  : ''
              }${value}%`
            },
          },
          tooltip: {
            callbacks: {
              label: ({ formattedValue, label }) => {
                return label + ': ' + formattedValue + '%'
              },
              title() {
                return tooltipTitle
              },
            },
          },
        },
        ...options,
      }}
    />
  )
}
