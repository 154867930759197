import { Box, Stack, Typography } from '@mui/material'

export default function Value() {
  return (
    <Stack className="py-10 container lg:px-[10%] max-w-6xl flex flex-col items-center justify-center">
      <h3 className="heading-2 min-w-[150px] text-center mb-5">VALUE</h3>
      <h4>私たちの価値観・行動指針</h4>
      <Stack className="my-5 hidden md:flex">
        <Box
          component="img"
          src="/static/about-value.png"
          alt="about-value"
          className="object-contain w-full h-full"
        ></Box>
      </Stack>
      <Stack className="md:hidden">
        <Box
          component="img"
          src="/static/about-value-sp.png"
          alt="about-value"
          className="object-contain w-full h-full"
        ></Box>
        <Box className="px-4">
          <Stack className="gap-2 mt-10">
            <Typography className="text-[#1d5dba] font-semibold text-lg">
              自分ごと
            </Typography>
            <Typography className="font-light">
              一人ひとりが当事者意識を持ち全力で取り組み、行動の結果に責任を持ちます
            </Typography>
          </Stack>
          <Stack className="gap-2 mt-10">
            <Typography className="text-[#1d5dba] font-semibold text-lg">
              利他行動
            </Typography>
            <Typography className="font-light">
              ホームコミュニティのみんなにとって善いことかを考え、行動します
            </Typography>
          </Stack>
          <Stack className="gap-2 mt-10">
            <Typography className="text-[#1d5dba] font-semibold text-lg">
              信頼と敬意
            </Typography>
            <Typography className="font-light">
              すべての人に信頼と敬意の心をもって対話します
            </Typography>
          </Stack>
          <Stack className="gap-2 mt-10">
            <Typography className="text-[#1d5dba] font-semibold text-lg">
              好奇心と探究心
            </Typography>
            <Typography className="font-light">
              常に新しいものへの好奇心・探求心を持ちつづけ、変化を楽しみながら成長します
            </Typography>
          </Stack>
          <Stack className="gap-2 mt-10">
            <Typography className="text-[#1d5dba] font-semibold text-lg">
              広い視野
            </Typography>
            <Typography className="font-light">
              固定観念にとらわれず、あらゆる可能性を追求します
            </Typography>
          </Stack>
          <Stack className="gap-2 mt-10">
            <Typography className="text-[#1d5dba] font-semibold text-lg">
              誠実
            </Typography>
            <Typography className="font-light">
              常に真剣に取り組み、正しく嘘のない対応をします
            </Typography>
          </Stack>
        </Box>
      </Stack>
    </Stack>
  )
}
