import { Box, Divider, Grid, Stack, Typography } from '@mui/material'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { getInterviews } from 'app/slices/Interview'
import LinkButton from 'components/LinkButton'
import NotFound from 'pages/NotFound'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

export default function FellowDetailComponent() {
  let { id } = useParams<{ id: string }>()
  const dispatch = useAppDispatch()
  const status = useAppSelector((s) => s.Interview.status)
  const data = useAppSelector((s) => s.Interview.list)
  const value = data.find((f) => f._id === id)
  const other = data.filter((f) => f._id !== id)
  useEffect(() => {
    if (status === 'init') dispatch(getInterviews())
  }, [status, dispatch])

  if (status === 'completed' && !value) return <NotFound />
  else if (!value)
    return <Stack className="flex-row justify-center text-xl">Loading...</Stack>
  else
    return (
      <Box className="container py-10 xl:max-w-[1100px]">
        <Stack gap={2}>
          <Stack direction="row" alignItems="center" gap={2}>
            <Typography
              className="px-8 py-1 text-white font-medium"
              sx={{ bgcolor: (theme) => theme.palette.secondary.main }}
            >
              {value.position}
            </Typography>
            <Typography className="font-medium">{value.author}</Typography>
          </Stack>
          <Stack direction="row">
            <Typography fontWeight={600}>{value.date}</Typography>
          </Stack>
          <Stack gap={8}>
            {value.blocks.map((block, i) => {
              return (
                <Grid container key={i} spacing={8}>
                  {block.map((v, j) => {
                    const col = 12 / block.reduce((p, c) => p + c.collapse, 0)
                    return (
                      <Grid
                        item
                        className=""
                        sm={12}
                        md={col * v.collapse}
                        key={v.id}
                      >
                        <Box
                          sx={{
                            marginTop: v.marginTop + 'px',
                            borderRadius: `${v.borderRadius || 0}px`,
                            bgcolor: v.bgColor || undefined,
                            padding: v.padding?.join('px ') + "px",
                          }}
                        >
                          {v.type === 'image' ? (
                            <Box
                              component="img"
                              width="100%"
                              src={v.value}
                              loading="lazy"
                            ></Box>
                          ) : (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: v.value || '',
                              }}
                              className="ql-editor view"
                            ></div>
                          )}
                        </Box>
                      </Grid>
                    )
                  })}
                </Grid>
              )
            })}
          </Stack>
        </Stack>
        <Divider />
        <Stack className="items-center justify-center gap-4 my-8">
          <Typography className="heading-2">他のインタビューも見る</Typography>
        </Stack>
        <Grid
          container
          spacing={{
            xs: 2,
            sm: 2,
            md: 3,
            lg: 4,
          }}
          alignItems="stretch"
        >
          {other.length ? (
            other.map((v, i) => {
              return (
                <Grid item xs={12} sm={6} md={4} lg={4} key={i}>
                  <Box className="relative pt-[130%] bg-transparent">
                    <Stack className="flex-row flex-wrap absolute top-0 left-0 z-10 bg-[#CDA83Faa] text-white items-center justify-start">
                      <Typography className="py-1 px-2 text-sm lg:text-base">{`${v.author.trim()}`}</Typography>
                      <Typography className="py-1 px-2 text-sm lg:text-base">{`${v.position.trim()}`}</Typography>
                    </Stack>
                    <Box
                      component="img"
                      src={v.thumbnail}
                      loading="lazy"
                      width={'100%'}
                      height={'100%'}
                      className="absolute inset-0 object-cover"
                    ></Box>
                  </Box>
                  <Stack className="justify-center items-center my-4">
                    <LinkButton
                      text="インタビューを読む"
                      arrowIcon
                      to={'/fellow/' + v._id}
                      sx={{
                        border: 'solid currentColor',
                        borderWidth: '2px 0',
                      }}
                    />
                  </Stack>
                </Grid>
              )
            })
          ) : (
            <Grid item xs={12}>
              <Stack className="justify-center items-center">
                <Typography className="text-center">...</Typography>
              </Stack>
            </Grid>
          )}
        </Grid>
      </Box>
    )
}
