import { Box, Stack, Typography } from '@mui/material'

export default function Discussion() {
  return (
    <Box className="container pb-10">
      <Stack className="items-center justify-center gap-4 mb-8">
        <Typography className="heading-2 min-w-[120px] text-center">
          座談会
        </Typography>
      </Stack>
      <Stack className="h-[300px] relative justify-center items-center">
        <Stack className="absolute inset-0">
          <Box
            component="img"
            src="/static/fellow-discussion.png"
            alt="fellow-discussion"
            className="w-full h-full object-cover brightness-75 rounded-lg"
            sx={{
              objectPosition: 'center 60%',
            }}
          ></Box>
        </Stack>
        <Typography className="text-white relative z-10 text-xl">
          coming soon・・・
        </Typography>
      </Stack>
    </Box>
  )
}
