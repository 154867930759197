import { Stack, Typography } from '@mui/material'
import LinkButton from 'components/LinkButton'

export default function NotFound() {
  return (
    <Stack className="w-screen h-[50vh] flex justify-center items-center">
      <Stack className="flex flex-col justify-center items-center p-10 gap-4 rounded-lg backdrop-blur-lg">
        <Typography
          component="h1"
          sx={{
            textShadow:
              '1px 1px 2px #ccc,2px 2px 2px #ccc,3px 3px 2px #ccc,4px 4px 1px #ccc',
          }}
          className="text-7xl font-bold text-rose-500"
        >
          404
        </Typography>
        <p>Page Not Found...</p>
        <LinkButton
          text="ホーム戻る"
          to="/"
          arrowIcon
          sx={{
            border: 'solid currentColor',
            borderWidth: '2px 0',
            width: 200,
          }}
        />
      </Stack>
    </Stack>
  )
}
