import { Box, Stack, Typography } from '@mui/material'
import { useAppSelector } from 'app/hooks'
import Top from 'components/About/Top'
import LinkButton from 'components/LinkButton'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export default function ApplicationError() {
  const nav = useNavigate()
  const applicationStatus = useAppSelector((s) => s.Recruit.applicationStatus)
  useEffect(() => {
    if (applicationStatus !== 'error') nav('/')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationStatus])

  return (
    <Box>
      <Top />
      <Stack className="container py-10 items-center justify-center gap-4">
        <Typography className="text-xl text-red-500 font-medium">
          エラーが発生しました
        </Typography>
        <Typography className="text-center leading-7">
          お問い合わせまでご連絡ください
        </Typography>
        <LinkButton
          text="ホーム戻る"
          to="/"
          arrowIcon
          sx={{
            border: 'solid currentColor',
            borderWidth: '2px 0',
            width: 200,
          }}
        />
      </Stack>
    </Box>
  )
}
