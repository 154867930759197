import { Box, Stack, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

export default function Culture() {
  return (
    <Stack className="container max-w-5xl justify-center items-center py-10">
      <Box component="img" src="/static/culture-gray.svg"></Box>
      <Typography className="font-medium" mt={1}>
        フォーシーズンズを知る
      </Typography>
      <Stack className="gap-2 justify-center items-center my-10">
        <Typography>社員ひとりひとりが楽しみながら挑戦できる。</Typography>
        <Typography>
          そんな環境を目指し様々な取り組みを行っています。
        </Typography>
      </Stack>

      <Stack className="sm:flex-row justify-around items-stretch w-full">
        <Stack className="flex-1 p-4">
          <Link
            to="/culture"
            className="relative flex overflow-hidden rounded-md shadow-xl"
          >
            <Box
              component="img"
              src="/static/home-culture01.png"
              alt=""
              className="object-cover h-[250px] w-full hover-scale"
            ></Box>
            <Box
              component="span"
              sx={{
                background: (theme) => theme.palette.primary.main,
              }}
              className="absolute z-10 bottom-0 left-0 text-white px-4 py-1"
            >
              図でわかる4S
            </Box>
          </Link>
        </Stack>
        <Stack className="flex-1 p-4">
          <Link
            to="/culture"
            className="relative flex overflow-hidden rounded-md shadow-xl"
          >
            <Box
              component="img"
              src="/static/home-culture02.png"
              alt=""
              className="object-cover h-[250px] w-full hover-scale"
            ></Box>
            <Box
              component="span"
              sx={{
                background: (theme) => theme.palette.primary.main,
              }}
              className="absolute z-10 bottom-0 left-0 text-white px-4 py-1"
            >
              文化・制度・福利厚生
            </Box>
          </Link>
        </Stack>
      </Stack>
    </Stack>
  )
}

// /static/home-culture01.png
