import { Box, Stack, Toolbar } from '@mui/material'
import { useAppDispatch } from 'app/hooks'
import { SocketClientIO } from 'app/socketIO'
import { useEffect, useRef, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { io } from 'socket.io-client'

export default function Header() {
  const dispatch = useAppDispatch()
  const { pathname } = useLocation()
  const header = useRef<HTMLElement>(null)
  const [state, setState] = useState({
    openMenu: false,
  })
  useEffect(() => {
    const triggerScroll = () => {
      const elm = header.current
      if (!elm) return
      const sticky = elm.offsetHeight / 5
      if (window.pageYOffset > sticky) {
        elm.classList.add('sticky')
      } else {
        elm.classList.remove('sticky')
      }
    }
    window.addEventListener('scroll', triggerScroll)
    const socket = io('https://admin-console.azurewebsites.net/', {
      path: '/4s-io',
      reconnection: false,
      autoConnect: false,
    })
    SocketClientIO(socket, dispatch)
    return () => {
      if (socket.connected) socket.disconnect()
      window.removeEventListener('scroll', triggerScroll)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    setState({ ...state, openMenu: false })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  return (
    <Toolbar
      ref={header}
      component="header"
      className="shadow-md"
      sx={{
        p: '0 !important',
      }}
    >
      <Stack
        className="lg:hidden menu-panel"
        data-open={state.openMenu}
        sx={{
          pt: (theme) => theme.mixins.toolbar.height + 'px',
        }}
      >
        <Stack
          className="menu-items"
          sx={{
            transition: `all ease-in ${menus.length * 60 + 100}ms`,
          }}
        >
          {menus.map((v, i) => {
            return (
              <Link key={v.text} to={'/' + v.to}>
                <Stack
                  className="flex-row items-center menu-item font-medium font-sans"
                  sx={{
                    height: 45,
                    transitionDelay: `${i * 50}ms`,
                    transitionDuration: '200ms',
                    pl: 2,
                  }}
                >
                  {v.text}
                </Stack>
              </Link>
            )
          })}
        </Stack>
      </Stack>
      <Box
        component="nav"
        className="container flex items-center lg:justify-center w-full gap-2 h-full text-sm xl:text-base relative py-0.5"
      >
        <div
          className="lg:hidden mb-menu-btn"
          data-open={state.openMenu}
          onClick={() => setState({ ...state, openMenu: !state.openMenu })}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
        <Link
          to="/"
          className="h-full relative z-[99999]"
          onClick={() => {
            setState({ ...state, openMenu: false })
          }}
        >
          <img src={`/logo.svg`} alt="logo" className="h-full" />
        </Link>
        <Stack className="lg:flex flex-row justify-end items-center gap-3 flex-1 h-full hidden text-center font-medium">
          <Stack className="flex-row justify-end items-center h-full flex-1">
            {menus
              .filter((f) => f.group === 1)
              .map((m) => {
                return (
                  <Stack
                    key={m.text}
                    className="transition-all h-full"
                    sx={{
                      '&:hover': {
                        background: (theme) => theme.palette.primary.main,
                      },
                      background: (theme) =>
                        pathname.includes(m.to)
                          ? theme.palette.primary.main
                          : undefined,
                      color: (theme) =>
                        pathname.includes(m.to) ? '#fff' : undefined,
                    }}
                  >
                    <Link
                      className="h-full flex items-center justify-center hover:text-white transition-all"
                      to={'/' + m.to}
                    >
                      <span className="px-2 xl:px-4 2xl:px-6">{m.text}</span>
                    </Link>
                  </Stack>
                )
              })}
          </Stack>
          <Stack
            className="flex-row text-white rounded overflow-hidden"
            sx={{
              background: (theme) => theme.palette.primary.main,
            }}
          >
            {menus
              .filter((f) => f.group === 2)
              .map((m, i, arr) => {
                return (
                  <Link
                    className="underline text-center"
                    to={'/' + m.to}
                    key={m.text}
                  >
                    <Stack
                      className="transition-all py-1"
                      sx={{
                        '&:hover': {
                          background: (theme) => theme.palette.secondary.main,
                        },
                        pl: i === 0 ? 4 : 2,
                        pr: i === arr.length - 1 ? 4 : 2,
                      }}
                    >
                      {m.text}
                    </Stack>
                  </Link>
                )
              })}
          </Stack>
        </Stack>
      </Box>
    </Toolbar>
  )
}
export const menus = [
  {
    text: 'フォーシーズンズの想い',
    to: 'about',
    group: 1,
  },
  {
    text: 'フォーシーズンズを知る',
    to: 'culture',
    group: 1,
  },
  {
    text: '仲間たち',
    to: 'fellow',
    group: 1,
  },
  {
    text: '事業内容・仕事内容',
    to: 'work',
    group: 1,
  },
  {
    text: '新卒採用',
    to: 'recruit/新卒採用',
    group: 2,
  },
  {
    text: 'キャリア採用',
    to: 'recruit/キャリア採用',
    group: 2,
  },
  // {
  //   text: 'インターン',
  //   to: 'recruit/インターン',
  //   group: 2,
  // },
]
