import { ThunkDispatch } from '@reduxjs/toolkit'
import { SocketEvent } from 'interfaces'
import { Socket } from 'socket.io-client'
import { AccountAction, getAccount } from './slices/Account'
import {
  InterviewAction,
  getInterview,
  getInterviews,
} from './slices/Interview'
import { NewsAction, getNews } from './slices/News'
import { RecruitAction, getRecruit } from './slices/Recruit'
import { getReport } from './slices/Report'
import { getSlides } from './slices/Slide'

export function SocketClientIO(
  socket: Socket,
  dispatch: ThunkDispatch<any, any, any>
) {
  socket.connect()
  socket.on('connect', () => {
    // AccountIO
    socket.on('AccountIO', (event: SocketEvent) => {
      process.env.NODE_ENV === 'development' && console.log(event)
      switch (event.action) {
        case 'created':
          dispatch(getAccount(event.id))
          break
        case 'updated':
          dispatch(getAccount(event.id))
          break
        case 'deleted':
          dispatch(AccountAction.deleteItem(event.id))
          break
        default:
          break
      }
    })
    // NewsIO
    socket.on('NewsIO', (event: SocketEvent) => {
      process.env.NODE_ENV === 'development' && console.log(event)
      switch (event.action) {
        case 'created':
        case 'updated':
          dispatch(getNews(event.id))
          break
        case 'deleted':
          dispatch(NewsAction.deleteItem(event.id))
          break
        default:
          break
      }
    })
    // ReportIO
    socket.on('ReportIO', (event: SocketEvent) => {
      process.env.NODE_ENV === 'development' && console.log(event)
      switch (event.action) {
        case 'updated':
          dispatch(getReport(event.id))
          break
        default:
          break
      }
    })

    // InterviewIO
    socket.on('InterviewIO', (event: SocketEvent) => {
      process.env.NODE_ENV === 'development' && console.log(event)
      switch (event.action) {
        case 'created':
        case 'updated':
          dispatch(getInterview(event.id))
          break
        case 'deleted':
          dispatch(InterviewAction.deleteItem(event.id))
          break
        case 'async':
          dispatch(getInterviews())
          break
        default:
          break
      }
    })
    // RecruitIO
    socket.on('RecruitIO', (event: SocketEvent) => {
      process.env.NODE_ENV === 'development' && console.log(event)
      switch (event.action) {
        case 'created':
        case 'updated':
          dispatch(getRecruit(event.id))
          break
        case 'deleted':
          dispatch(RecruitAction.deleteItem(event.id))
          break
        default:
          break
      }
    })
    // SlideIO
    socket.on('SlideIO', (event: SocketEvent) => {
      process.env.NODE_ENV === 'development' && console.log(event)
      switch (event.action) {
        case 'created':
        case 'updated':
          dispatch(getSlides())
          break
        default:
          break
      }
    })

    // -------------
  })
}
