import { Box, Stack } from '@mui/material'
import anime from 'animejs'
import { useEffect } from 'react'
import s1 from './community/purpose01.png'
import s2 from './community/purpose02.png'
import s3 from './community/purpose03.png'
import s4 from './community/purpose04.png'
import s5 from './community/purpose05.png'
import s6 from './community/purpose06.png'
import s7 from './community/purpose07.png'
import s8 from './community/purpose08.png'
import s9 from './community/purpose09.png'
import s10 from './community/purpose10.png'
import s11 from './community/purpose11.png'
import s12 from './community/purpose12.png'

export default function Community() {
  useEffect(() => {
    anime({
      targets: '.ss .el',
      easing: 'easeInOutQuad',
      loop: true,
      duration: 800,
      opacity: 1,
      zIndex: {
        value: '-=1',
        duration: 2000,
      },
      delay: anime.stagger(800),
    })
    anime({
      targets: '.ss .s1',
      easing: 'easeInOutQuad',
      duration: 1000,
      loop: 1,
      scale: [
        { value: 1.2, duration: 400 },
        { value: 0.8, duration: 200 },
        { value: 1, duration: 200 },
      ],
    })
  }, [])
  return (
    <Stack className="w-full pt-[70%] relative ss">
      <Box className="absolute inset-0 opacity-0 z-30 el s1">
        <Box
          component="img"
          src={s1}
          alt="s1"
          className="w-full h-full object-contain"
        />
      </Box>
      <Box className="absolute inset-0 opacity-0 z-30 el s2">
        <Box
          component="img"
          src={s2}
          alt="s2"
          className="w-full h-full object-contain"
        />
      </Box>
      <Box className="absolute inset-0 opacity-0 z-30 el s3">
        <Box
          component="img"
          src={s3}
          alt="s3"
          className="w-full h-full object-contain"
        />
      </Box>
      <Box className="absolute inset-0 opacity-0 z-30 el s4">
        <Box
          component="img"
          src={s4}
          alt="s4"
          className="w-full h-full object-contain"
        />
      </Box>
      <Box className="absolute inset-0 opacity-0 z-30 el s5">
        <Box
          component="img"
          src={s5}
          alt="s5"
          className="w-full h-full object-contain"
        />
      </Box>
      <Box className="absolute inset-0 opacity-0 z-30 el s6">
        <Box
          component="img"
          src={s6}
          alt="s6"
          className="w-full h-full object-contain"
        />
      </Box>
      <Box className="absolute inset-0 opacity-0 z-30 el s7">
        <Box
          component="img"
          src={s7}
          alt="s7"
          className="w-full h-full object-contain"
        />
      </Box>
      <Box className="absolute inset-0 opacity-0 z-30 el s8">
        <Box
          component="img"
          src={s8}
          alt="s8"
          className="w-full h-full object-contain"
        />
      </Box>
      <Box className="absolute inset-0 opacity-0 z-30 el s9">
        <Box
          component="img"
          src={s9}
          alt="s9"
          className="w-full h-full object-contain"
        />
      </Box>
      <Box className="absolute inset-0 opacity-0 z-30 el s10">
        <Box
          component="img"
          src={s10}
          alt="s10"
          className="w-full h-full object-contain"
        />
      </Box>
      <Box className="absolute inset-0 opacity-0 z-30 el s11">
        <Box
          component="img"
          src={s11}
          alt="s11"
          className="w-full h-full object-contain"
        />
      </Box>
      <Box className="absolute inset-0 opacity-0 z-30 el s12">
        <Box
          component="img"
          src={s12}
          alt="s12"
          className="w-full h-full object-contain"
        />
      </Box>
    </Stack>
  )
}
