import { Box, Button, Stack, Typography } from '@mui/material'
import { format } from 'date-fns'
import { NoteType } from 'interfaces'
import { useEffect, useState } from 'react'
import {
  AiFillHeart,
  AiFillTwitterCircle,
  AiOutlineTwitter,
} from 'react-icons/ai'
import { BsFacebook } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import axiosClient from 'utils/axiosClient'

export default function FollowUs() {
  const [noteContents, setNoteContents] = useState<NoteType[]>([])
  useEffect(() => {
    async function getNoteData() {
      const { data } = await axiosClient('/api/external/note')
      if (Array.isArray(data)) {
        setNoteContents(data.slice(0, 20))
      }
    }
    getNoteData()
  }, [])

  return (
    <Box
      sx={{
        background: `linear-gradient(180deg, #F8F0DF 0%, transparent 100%)`,
      }}
    >
      <Stack className="container py-10 relative justify-center items-center">
        <Stack className="justify-center items-center">
          <Box component="img" src="/static/followUs.svg"></Box>
          <Typography className="font-medium" mt={1}>
            SNS・ブログ
          </Typography>
        </Stack>
        <Stack className="relative w-full min-h-[600px]">
          <Box
            component="img"
            src="/static/4sbg.png"
            alt="4sbg"
            className="absolute lg:w-1/2 w-full right-0 h-full object-contain object-center lg:object-right"
          ></Box>
          <Stack className="container relative z-10">
            <Stack className="flex-row items-center justify-center gap-[5vw] mt-4 lg:mt-auto lg:w-1/2 max-w-sm">
              <Link to="https://twitter.com/0004s_marketing" target="_blank">
                <AiOutlineTwitter className="text-6xl text-[#2ba1f2] hover:drop-shadow-md transition-all" />
              </Link>
              <Link to="https://www.facebook.com/0004s" target="_blank">
                <BsFacebook className="text-5xl text-[#1877e8] hover:drop-shadow-md transition-all" />
              </Link>
              <Link to="https://note.com/0004s_marketing" target="_blank">
                <img
                  src="/static/note-title.svg"
                  alt="note"
                  className="hover:drop-shadow-md transition-all h-12 w-auto"
                />
              </Link>
            </Stack>
            <Stack className="mt-4 lg:mt-auto lg:w-2/3 max-w-3xl gap-3 px-2 py-4 max-h-[600px] overflow-y-auto bg-white/20">
              {noteContents.map((note) => {
                return (
                  <Stack
                    key={note.id}
                    className="bg-white/80 rounded-md shadow-lg backdrop-blur-[3px]"
                    sx={{
                      border: '1px solid #eee',
                      p: 2,
                    }}
                  >
                    <Link to="https://note.com/0004s_marketing" target="_blank">
                      <Stack className="flex-row lg:items-center gap-2 ">
                        <Box
                          component="img"
                          src={note.user.userProfileImagePath}
                          alt=""
                          className="rounded-full overflow-hidden w-12 h-12"
                        />
                        <Stack className="lg:flex-row lg:gap-2 lg:items-center">
                          <Typography
                            variant="h4"
                            className="font-medium text-base"
                          >
                            {note.user.nickname}
                          </Typography>
                          <Typography className="font-light text-sm">
                            @{note.user.urlname}・
                            {format(new Date(note.publishAt), 'MM月dd日')}
                          </Typography>
                        </Stack>
                      </Stack>
                    </Link>
                    <Stack className="flex-col-reverse lg:flex-row w-full gap-2 my-2">
                      <Stack className="flex-1">
                        <Typography className="font-medium">
                          {note.name}
                        </Typography>
                        <div
                          className="text-xs max-h-[200px] overflow-hidden pl-2 flex flex-col"
                          dangerouslySetInnerHTML={{
                            __html: `<p>${note.body
                              .split('\n')
                              .slice(0, 4)
                              .join('<br/>')}<br/>...
                            </p>
                            `,
                          }}
                        ></div>
                        <Stack className="flex-row justify-center">
                          <Link
                            to={note.noteUrl}
                            target="_blank"
                            className="note-link text-xs text-pink-600 hover:text-rose-500"
                          >
                            詳しくはこちら
                          </Link>
                        </Stack>
                      </Stack>
                      <Box className="w-3/4 lg:w-1/3">
                        <Box
                          component="img"
                          src={note.eyecatch}
                          alt=""
                          className="w-full h-auto object-contain rounded-sm overflow-hidden shadow-md bg-white/90"
                        />
                      </Box>
                    </Stack>
                    <Stack className="flex-row flex-wrap gap-1 italic py-2">
                      {note.hashtags.map((tag) => {
                        return (
                          <Typography
                            className="text-[0.65rem] bg-black/5 rounded-sm px-1 text-gray-500 font-light"
                            key={tag.hashtag.name}
                          >
                            {tag.hashtag.name}
                          </Typography>
                        )
                      })}
                    </Stack>
                    <Stack className="flex-row my-2 justify-around gap-2">
                      <Typography className="flex items-center gap-1">
                        <AiFillHeart className="text-xl text-rose-500 mt-0.5" />
                        {note.likeCount}
                      </Typography>

                      <Button
                        onClick={() => {
                          window.open(
                            note.facebookShareUrl,
                            'mozillaWindow',
                            'left=0,top=0,width=400,height=500'
                          )
                        }}
                        className="flex items-center gap-1 p-0.5 text-xs text-[#1877e8]"
                      >
                        <BsFacebook className="text-xl" />
                        Facebook Share
                      </Button>
                      <Button
                        onClick={() => {
                          window.open(
                            note.twitterShareUrl,
                            'mozillaWindow',
                            'left=0,top=0,width=500,height=600'
                          )
                        }}
                        className="flex items-center gap-1 p-0.5 text-xs text-[#2ba1f2]"
                      >
                        <AiFillTwitterCircle className="text-xl" />
                        Twitter Share
                      </Button>
                    </Stack>
                  </Stack>
                )
              })}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  )
}
