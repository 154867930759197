import { Box, Stack, Typography } from '@mui/material'
import Arrow from 'components/Arrow'

export default function Purpose() {
  return (
    <>
      <Box className="py-10 bg-bottom relative">
        <Stack className="absolute inset-0">
          <Box
            component="img"
            src="/static/about-purpose.png"
            alt="about-purpose"
            className="w-full h-full object-cover object-bottom"
          ></Box>
        </Stack>
        <Stack className="container lg:px-[10%] max-w-6xl items-center justify-center relative z-10 mb-[10%]">
          <Typography className="heading-2 min-w-[150px] text-center mb-8 text-white">
            PURPOSE
          </Typography>
          <Stack className="flex-row justify-center w-full lg:gap-2 font-medium lg:tracking-[4px] text-xl drop-shadow-md">
            <Stack className="lg:flex-1 justify-center items-center gap-1">
              <Typography className="font-semibold text-sm lg:text-lg">
                共に働く仲間<span className="inline lg:hidden">、</span>
              </Typography>
              <div className="hidden lg:flex h-[2px] w-4/5 bg-[#CDA83F]"></div>
              <div className="h-10 hidden lg:flex justify-center items-center">
                <Arrow className="text-[#CDA83F] h-3 rotate-[90deg]" />
              </div>
            </Stack>
            <Stack className="lg:flex-1 justify-center items-center gap-1">
              <Typography className="font-semibold text-sm lg:text-lg">
                お客さま<span className="inline lg:hidden">、</span>
              </Typography>
              <div className="hidden lg:flex h-[2px] w-4/5 bg-[#CDA83F]"></div>
              <div className="h-10 hidden lg:flex justify-center items-center">
                <Arrow className="text-[#CDA83F] h-3 rotate-[90deg]" />
              </div>
            </Stack>
            <Stack className="lg:flex-1 justify-center items-center gap-1">
              <Typography className="w-max font-semibold text-sm lg:text-lg">
                人々が幸せになること
              </Typography>
              <div className="hidden lg:flex h-[2px] w-4/5 bg-[#CDA83F]"></div>
              <div className="h-10 hidden lg:flex justify-center items-center">
                <Arrow className="text-[#CDA83F] h-3 rotate-[90deg]" />
              </div>
            </Stack>
          </Stack>
          <Stack className="hidden lg:flex flex-row w-full gap-4 font-normal px-1 drop-shadow-md">
            <p className="flex-1">常にチャレンジして ワクワクすること</p>
            <p className="flex-1">
              価値を共創することで「いいね」と言ってもらえるようなサービス、仕事を提供すること
            </p>
            <p className="flex-1">
              わたしたちが影響範囲を拡げ続けることで社会に貢献し、人々が幸せになる
            </p>
          </Stack>
        </Stack>
      </Box>
      <Stack className="container lg:hidden gap-10 max-w-sm mx-auto my-10">
        <Typography className="text-xl font-semibold">
          共に働く仲間、
        </Typography>
        <Stack className="flex-row items-baseline gap-2">
          <Arrow className="text-[#CDA83F] h-1.5" />
          <Typography className="flex-1">
            常にチャレンジしてワクワクすること
          </Typography>
        </Stack>
        <Typography className="text-xl font-semibold">お客さま、</Typography>
        <Stack className="flex-row items-baseline gap-2">
          <Arrow className="text-[#CDA83F] h-1.5" />
          <Typography className="flex-1">
            価値を共創することで「いいね」と言ってもらえるようなサービス、仕事を提供すること
          </Typography>
        </Stack>
        <Typography className="text-xl font-semibold">
          人々が幸せになること
        </Typography>
        <Stack className="flex-row items-baseline gap-2">
          <Arrow className="text-[#CDA83F] h-1.5" />
          <Typography className="flex-1">
            わたしたちが影響範囲を拡げ続けことで社会に貢献し、人々が幸せになる
          </Typography>
        </Stack>
      </Stack>
    </>
  )
}
