import { Box, Stack, Typography } from '@mui/material'
import anime from 'animejs'
import { useEffect } from 'react'
import ChangeTheLifeStyle from '../Home/ChangeTheLifeStyle'
import Community from './Community'

export default function Message() {
  useEffect(() => {
    const tl = anime.timeline({
      targets: '.ChangeTheLifeStyle path',
      easing: 'easeInOutSine',
      duration: 500,
      loop: 1,
    })
    tl.add({
      strokeDashoffset: [anime.setDashoffset, 1],
      duration: 1500,
      delay: anime.stagger(100),
      direction: 'alternate',
    }).add({
      fill: '#163259',
      strokeWidth: '0',
    })
  }, [])

  return (
    <Box className="container py-10">
      <Stack className="items-center justify-center gap-5">
        <h3 className="heading-2">大切にしている考え方</h3>
        <Stack className="text-[#163259] w-full max-w-xl py-6">
          <ChangeTheLifeStyle stroke="#163259" strokeWidth={5} />
        </Stack>
        <Stack>
          <p className="lg:text-center leading-7 font-medium">
            何のために変わるのか。
            <br />
            自分自身のためではありません。
            <br />
            わたしたちは、ホームコミュニティが「幸せ」な状態であるために変化を恐れず行動（価値を共創）します。
            <br />
            そして、自分自身が全力で変化を恐れず行動することで、ホームコミュニティの範囲を拡げ、
            <br />
            社会全体が「幸せ」な状態であることを目指します。
          </p>
        </Stack>
        <Stack className="max-w-3xl w-full mt-4 lg:mt-0">
          <Community />
          <Typography className="text-center">
            ※ ホームコミュニティ：家族・パートナー・自身の周囲・お客さま
          </Typography>
        </Stack>
      </Stack>
    </Box>
  )
}
