import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IAccount, Status } from 'interfaces'
import axiosClient from 'utils/axiosClient'

const initialState: {
  list: IAccount[]
  status: Status
} = {
  list: [],
  status: 'init',
}

export const getAccounts = createAsyncThunk('Account/getAccounts', async () => {
  const { data } = await axiosClient.get<IAccount[]>('/api/account')
  return data
})
export const getAccount = createAsyncThunk(
  'Account/getAccount',
  async (_id: string) => {
    const { data } = await axiosClient.get<IAccount>('/api/account/' + _id)
    return data
  }
)
export const logout = createAsyncThunk('Account/logout', async () => {
  await axiosClient.post('/auth/logout')
})

const AccountSlice = createSlice({
  name: 'Account',
  initialState,
  reducers: {
    deleteItem(state, { payload }: PayloadAction<string>) {
      if (state.list) {
        const index = state.list.findIndex((f) => f._id === payload)
        if (index >= 0) {
          state.list.splice(index, 1)
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAccounts.pending, (state) => {
      state.status = 'pending'
    })
    builder.addCase(getAccounts.rejected, (state) => {
      state.status = 'rejected'
    })
    builder.addCase(getAccounts.fulfilled, (state, { payload }) => {
      state.list = payload
      state.status = 'fulfilled'
    })
    builder.addCase(logout.fulfilled, () => {
      if (window) window.location.reload()
    })
    builder.addCase(getAccount.fulfilled, (state, { payload }) => {
      const index = state.list.findIndex((f) => f._id === payload._id)
      if (index >= 0) {
        state.list.splice(index, 1, payload)
      } else {
        state.list.push(payload)
      }
    })
  },
})

export const AccountAction = AccountSlice.actions

export const AccountReducer = AccountSlice.reducer
