import { Box, Button, Stack, Typography } from '@mui/material'
import { ReactElement } from 'react'
import { AiOutlineCloudUpload } from 'react-icons/ai'

interface Props {
  file?: File
  disabled?: boolean
  error?: boolean
  title: string | ReactElement
  accept?: string
  subText?: ReactElement
  onSelectFile: (file: File) => void
}
export default function FileUpload({
  file,
  disabled,
  error,
  title,
  accept = '.xlsx,.xls,.doc,.docx,.pdf',
  subText,
  onSelectFile,
}: Props) {
  return (
    <Button
      variant="outlined"
      disabled={disabled}
      component="label"
      sx={{
        textTransform: 'initial',
        border: error ? '1px solid red' : undefined,
        color: error ? 'red' : undefined,
      }}
    >
      <Stack className="gap-1">
        <Stack className="flex-row justify-center items-center gap-2">
          <Box>{title}</Box>
          <AiOutlineCloudUpload className="text-xl" />
        </Stack>
        {subText}
        {!!file && (
          <Typography className="bg-white/50 p-2 text-sm shadow-md rounded-md">
            アップロードしたファイル：
            <span className="text-red-500">{file.name}</span>
          </Typography>
        )}
      </Stack>
      <input
        type="file"
        hidden
        accept={accept}
        onChange={(e) => {
          if (e.target.files && e.target.files[0]) {
            onSelectFile(e.target.files[0])
          }
        }}
      />
    </Button>
  )
}
