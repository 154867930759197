import {
  Action,
  combineReducers,
  configureStore,
  ThunkAction,
} from '@reduxjs/toolkit'
import { UIReducer as UI } from './slices/UI'
import { AccountReducer as Account } from './slices/Account'
import { NewsReducer as News } from './slices/News'
import { ReportReducer as Report } from './slices/Report'
import { InterviewReducer as Interview } from './slices/Interview'
import { RecruitReducer as Recruit } from './slices/Recruit'
import { SlideReducer as Slide } from './slices/Slide'

export const rootReducer = combineReducers({
  UI,
  Account,
  News,
  Report,
  Interview,
  Recruit,
  Slide,
})

export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production',
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
