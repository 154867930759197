import { Box, Stack, Typography } from '@mui/material'
import React from 'react'

export default function Recruit() {
  return (
    <Stack className="py-10 relative w-full h-[260px] bg-[#5abfc5]">
      {/* <Box className="absolute inset-0 z-0 overflow">
        <Box
          component="img"
          src="/static/home-recruit.png"
          alt="recruit"
          className="w-full h-full object-cover brightness-110"
          sx={{
            objectPosition: 'right 70%',
          }}
        ></Box>
      </Box> */}
      <Stack className="container relative z-10 justify-center items-center text-white">
        <Stack className="justify-center items-center">
          <Box component="img" src="/static/recruit-white.svg"></Box>
          <Typography className="font-medium" mt={1}>
            採用情報
          </Typography>
        </Stack>
        <Stack className="justify-center items-center my-8 gap-2">
          <Typography className="text-center">
            共に助け合い高め合う。
            <br />
            私たちの大切な想いを同じくする仲間を募集しています。
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  )
}
