import { Box, Button, Stack, Typography } from '@mui/material'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { getNewsList } from 'app/slices/News'
import { format } from 'date-fns'
import _ from 'lodash'
import { useEffect, useState } from 'react'

const DEFAULT_VIEW_ROW = 5
export default function News() {
  const dispatch = useAppDispatch()
  const status = useAppSelector((s) => s.News.status)
  const newsList = useAppSelector((s) => s.News.list)
  const [viewNum, setViewNum] = useState(DEFAULT_VIEW_ROW)
  useEffect(() => {
    if (status === 'init') {
      dispatch(getNewsList())
    }
  }, [dispatch, status])

  return (
    <Box id="home-top-news" className="bg-news-pattern">
      <Stack className="container max-w-5xl justify-center items-center py-10">
        <Box component="img" src="/static/news-gray.svg"></Box>
        <Typography className="font-medium" mt={1}>
          お知らせ
        </Typography>
        <Stack>
          {_.orderBy(newsList, ['date'], ['desc'])
            .slice(0, viewNum)
            .map((news) => {
              return (
                <Stack
                  key={news._id}
                  className="lg:flex-row gap-2 py-6 lg:py-2 items-baseline"
                  sx={{
                    borderBottom: '1px dotted #ccc',
                  }}
                >
                  <Typography>
                    {format(new Date(news.date), 'yyyy.MM.dd')}
                  </Typography>
                  <Typography
                    className="min-w-[120px] text-center text-white py-0.5"
                    sx={{
                      bgcolor: (theme) => theme.palette.primary.main,
                    }}
                  >
                    {news.category}
                  </Typography>
                  <div
                    className="ql-editor view flex-1"
                    dangerouslySetInnerHTML={{
                      __html: news.title,
                    }}
                  ></div>
                </Stack>
              )
            })}
        </Stack>
        <Button
          disabled={viewNum >= newsList.length}
          size="small"
          variant="text"
          sx={{ mt: 2, width: 100 }}
          onClick={() => setViewNum(viewNum + DEFAULT_VIEW_ROW)}
        >
          MORE
        </Button>
      </Stack>
    </Box>
  )
}
