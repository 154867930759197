import { Box } from '@mui/material'
import FellowDetailComponent from 'components/Fellow/Detail'
import Top from 'components/Fellow/Top'
import { Helmet } from 'react-helmet'

export default function FellowDetail() {
  return (
    <Box className="pb-10">
      <Helmet>
        <title>フォーシーズンズ | インタビュー</title>
      </Helmet>
      <Top />
      <FellowDetailComponent />
    </Box>
  )
}
