import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { GlobalMessage, IAccount } from 'interfaces'

const initialState: {
  loginUser: IAccount
  globalMessages: GlobalMessage[]
  target?: string
} = {
  loginUser: {
    _id: '',
    email: '',
    name: '',
    role: 0,
  },
  globalMessages: [],
}

const UISlice = createSlice({
  name: 'UI',
  initialState,
  reducers: {
    setLoginUser(
      state,
      { payload }: PayloadAction<typeof initialState.loginUser>
    ) {
      state.loginUser = payload
    },
    setTarget(state, { payload }: PayloadAction<string | undefined>) {
      state.target = payload
    },
    addGlobalMessage(
      state,
      { payload }: PayloadAction<Omit<GlobalMessage, 'id'>>
    ) {
      const id = new Date().getTime()
      state.globalMessages.push({ ...payload, id })
    },
    removeGlobalMessage(state, { payload }: PayloadAction<number>) {
      const index = state.globalMessages.findIndex(
        (f) => f.id === Number(payload)
      )
      if (index >= 0) {
        state.globalMessages.splice(index, 1)
      }
    },
  },
  extraReducers: (builder) => {},
})

export const UIAction = UISlice.actions

export const UIReducer = UISlice.reducer
