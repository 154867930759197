import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISlide, Status } from 'interfaces'
import axiosClient from 'utils/axiosClient'

const initialState: {
  list: ISlide[]
  status: Status
} = {
  list: [],
  status: 'init',
}

export const getSlides = createAsyncThunk('Slide/getSlides', async () => {
  const { data } = await axiosClient.get<ISlide[]>('/api/slide')
  return data
})

const SlideSlice = createSlice({
  name: 'Slide',
  initialState,
  reducers: {
    deleteItem(state, { payload }: PayloadAction<string>) {
      if (state.list) {
        const index = state.list.findIndex((f) => f.name === payload)
        if (index >= 0) {
          state.list.splice(index, 1)
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSlides.pending, (state) => {
      state.status = 'pending'
    })
    builder.addCase(getSlides.rejected, (state) => {
      state.status = 'rejected'
    })
    builder.addCase(getSlides.fulfilled, (state, { payload }) => {
      state.list = payload
      state.status = 'fulfilled'
    })
  },
})

export const SlideAction = SlideSlice.actions

export const SlideReducer = SlideSlice.reducer
