import { InputAdornment, Stack, TextField, Typography } from '@mui/material'
import { useState } from 'react'
import { BsFillInfoCircleFill, BsGithub } from 'react-icons/bs'
import { FaGitlab } from 'react-icons/fa'

interface Props {
  disabled?: boolean
  onChange: (val: string) => void
}
export default function Media({ disabled, onChange }: Props) {
  const [github, setGithub] = useState('')
  const [gitlab, setGitlab] = useState('')
  const [free, setFree] = useState('')
  const changeMedia = () => {
    onChange(
      JSON.stringify({
        github,
        gitlab,
        free,
      })
    )
  }
  return (
    <Stack
      className="p-4 rounded gap-2 bg-white/30"
      sx={{
        border: (theme) => `1px solid ${theme.palette.secondary.dark}`,
      }}
    >
      <Typography className="text-base font-normal">
        お知らせいただけるメディア（Github/ポートフォリオサイト等）
      </Typography>
      <TextField
        variant="standard"
        disabled={disabled}
        margin="dense"
        fullWidth
        size="small"
        label={'Github'}
        placeholder="例）https://github.com/xxx"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <BsGithub className="text-xl text-[#111]" />
            </InputAdornment>
          ),
        }}
        value={github}
        onChange={(e) => setGithub(e.target.value)}
        onBlur={() => changeMedia()}
      />
      <TextField
        variant="standard"
        disabled={disabled}
        margin="dense"
        fullWidth
        size="small"
        label={'GitLab'}
        placeholder="例）https://gitlab.com/xxx"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <FaGitlab className="text-xl text-[#e2432a]" />
            </InputAdornment>
          ),
        }}
        value={gitlab}
        onChange={(e) => setGitlab(e.target.value)}
        onBlur={() => changeMedia()}
      />
      <TextField
        variant="outlined"
        disabled={disabled}
        margin="dense"
        fullWidth
        size="small"
        label={
          <span className="flex items-center gap-1">
            <BsFillInfoCircleFill className="text-xl mt-1" />
            ポートフォリオサイト等
          </span>
        }
        placeholder="自由に入力して下さい"
        multiline
        minRows={2}
        InputLabelProps={{
          shrink: true,
        }}
        sx={{
          '& textarea': {
            mt: 1,
          },
        }}
        value={free}
        onChange={(e) => setFree(e.target.value)}
        onBlur={() => changeMedia()}
      />
    </Stack>
  )
}
