import { Box, Button, Grid } from '@mui/material'
import { useAppDispatch } from 'app/hooks'
import { UIAction } from 'app/slices/UI'

export default function Services() {
  const dispatch = useAppDispatch()
  return (
    <Box className="py-10 bg-[#D1E5F8]">
      <Box className="container text-white">
        <Grid
          container
          spacing={{
            xs: 1,
            sm: 1.5,
            md: 2.5,
          }}
          alignItems="stretch"
        >
          <Grid item xs={12} sm={6}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                dispatch(UIAction.setTarget('#SI事業部／インフラ'))
              }}
              className="w-full py-4 rounded text-center font-medium"
            >
              SI事業部／インフラ
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                dispatch(UIAction.setTarget('#SI事業部／Web開発'))
              }}
              className="w-full py-4 rounded text-center font-medium"
            >
              SI事業部／Web開発
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                dispatch(UIAction.setTarget('#SI事業部／クラウド'))
              }}
              className="w-full py-4 rounded text-center font-medium"
            >
              SI事業部／クラウド
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              color="inherit"
              variant="contained"
              onClick={() => {
                dispatch(UIAction.setTarget('#飲食事業'))
              }}
              className="bg-[#31AFB6] w-full py-4 rounded text-center font-medium"
              sx={{
                '&:hover': {
                  bgcolor: '#218287',
                },
              }}
            >
              飲食事業
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
