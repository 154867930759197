import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IInterview, Status } from 'interfaces'
import axiosClient from 'utils/axiosClient'

const initialState: {
  list: IInterview[]
  status: Status
} = {
  list: [],
  status: 'init',
}

export const getInterviews = createAsyncThunk(
  'Interview/getInterviews',
  async () => {
    const { data } = await axiosClient.get<IInterview[]>('/api/interview')
    return data.filter((f) => f.public)
  }
)
export const getInterview = createAsyncThunk(
  'Interview/getInterview',
  async (_id: string) => {
    const { data } = await axiosClient.get<IInterview>('/api/interview/' + _id)
    return data
  }
)

const InterviewSlice = createSlice({
  name: 'Interview',
  initialState,
  reducers: {
    deleteItem(state, { payload }: PayloadAction<string>) {
      if (state.list) {
        const index = state.list.findIndex((f) => f._id === payload)
        if (index >= 0) {
          state.list.splice(index, 1)
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getInterviews.pending, (state) => {
      state.status = 'pending'
    })
    builder.addCase(getInterviews.rejected, (state) => {
      state.status = 'rejected'
    })
    builder.addCase(getInterviews.fulfilled, (state, { payload }) => {
      state.list = payload
      state.status = 'fulfilled'
    })
    builder.addCase(getInterview.fulfilled, (state, { payload }) => {
      const index = state.list.findIndex((f) => f._id === payload._id)
      if (index >= 0) {
        if (payload.public) state.list.splice(index, 1, payload)
        else state.list.splice(index, 1)
      } else {
        if (payload.public) state.list.push(payload)
      }
    })
  },
})

export const InterviewAction = InterviewSlice.actions

export const InterviewReducer = InterviewSlice.reducer
