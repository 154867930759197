import { Grid, Paper, Stack, Typography } from '@mui/material'

export default function Welfare() {
  return (
    <Grid
      container
      spacing={{
        xs: 2,
        md: 3,
        lg: 5,
      }}
      alignItems="stretch"
    >
      <Grid item xs={12}>
        <Typography component="h4" className="heading-3">
          福利厚生
        </Typography>
        <Typography>
          従業員が安心して働ける環境・健康的で豊かな生活を送るための制度です。
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper elevation={8} className="min-h-[150px] h-full rounded-lg">
          <Stack className="p-5">
            <Stack className="flex-row items-center gap-2">
              <img src="/static/time-icon.png" alt="time" className="w-8 h-8" />
              <Typography className="text-lg text-[#1D5DBA] font-medium">
                フレックスタイム制
              </Typography>
            </Stack>
            <Stack className="pl-8 gap-2">
              <Typography className="font-light">
                出退勤時間が自分で決められます。
              </Typography>
              <Stack className="ml-4">
                <Typography className="text-sm font-light">
                  フレキシブルタイム／8:00〜20:00
                </Typography>
                <Typography className="text-sm font-light">
                  コアタイム／11:00〜15:00
                </Typography>
                <Typography className="text-sm font-light">
                  （参画部署・プロジェクトにより変更有）
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Paper>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper elevation={8} className="min-h-[150px] h-full rounded-lg">
          <Stack className="p-5">
            <Stack className="flex-row items-center gap-2 mb-2">
              <img
                src="/static/child-care-icon.png"
                alt="child-care"
                className="w-8 h-8"
              />
              <Typography className="text-lg text-[#1D5DBA] font-medium">
                育休制度（男女）
              </Typography>
            </Stack>
            <Stack className="pl-8 gap-2">
              <Typography className="font-light">
                男女ともに育児休業の取得が可能です（最長2年）。女性取得実績有。男性短期育児休業の取得実績有。
              </Typography>
            </Stack>
          </Stack>
        </Paper>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper elevation={8} className="min-h-[150px] h-full rounded-lg">
          <Stack className="p-5">
            <Stack className="flex-row items-center gap-2 mb-2">
              <img
                src="/static/salary-up-icon.png"
                alt="salary-up"
                className="w-8 h-8"
              />
              <Typography className="text-lg text-[#1D5DBA] font-medium">
                昇給年2回（4月、10月）
              </Typography>
            </Stack>
            <Stack className="pl-8 gap-2">
              <Typography className="font-light">
                昇給時には上長との個別面談・目標設定・自己評価等を行います。
              </Typography>
            </Stack>
          </Stack>
        </Paper>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper elevation={8} className="min-h-[150px] h-full rounded-lg">
          <Stack className="p-5">
            <Stack className="flex-row items-center gap-2 mb-2">
              <img
                src="/static/bonus-icon.png"
                alt="bonus"
                className="w-8 h-8"
              />
              <Typography className="text-lg text-[#1D5DBA] font-medium">
                賞与年2回（8月、1月）
              </Typography>
            </Stack>
            <Stack className="pl-8 gap-2">
              <Typography className="font-light">
                賞与は年2回。事業業績やチームや個人の実績を勘案して支給します。
              </Typography>
            </Stack>
          </Stack>
        </Paper>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper elevation={8} className="min-h-[150px] h-full rounded-lg">
          <Stack className="p-5">
            <Stack className="flex-row items-center gap-2 mb-2">
              <img
                src="/static/insurance-icon.png"
                alt="insurance"
                className="w-8 h-8"
              />
              <Typography className="text-lg text-[#1D5DBA] font-medium">
                各種社会保険完備
              </Typography>
            </Stack>
            <Stack className="pl-8 gap-2">
              <Typography className="font-light">
                雇用保険、労災保険、厚生年金保険、健康保険を完備しています。
              </Typography>
            </Stack>
          </Stack>
        </Paper>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper elevation={8} className="min-h-[150px] h-full rounded-lg">
          <Stack className="p-5">
            <Stack className="flex-row items-center gap-2 mb-2">
              <img
                src="/static/retirement-icon.png"
                alt="retirement"
                className="w-8 h-8"
              />
              <Typography className="text-lg text-[#1D5DBA] font-medium">
                退職金制度
              </Typography>
            </Stack>
            <Stack className="pl-8 gap-2">
              <Typography className="font-light">
                勤続年数に応じて退職金を支給します。（退職手当、中小企業退職金共済、福祉はぐくみ企業年金基金）
              </Typography>
            </Stack>
          </Stack>
        </Paper>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper elevation={8} className="min-h-[150px] h-full rounded-lg">
          <Stack className="p-5">
            <Stack className="flex-row items-center gap-2 mb-2">
              <img
                src="/static/people-01-icon.png"
                alt="people-01"
                className="w-8 h-8"
              />
              <Typography className="text-lg text-[#1D5DBA] font-medium">
                関東ITソフトウェア健康保険組合加入
              </Typography>
            </Stack>
            <Stack className="pl-8 gap-2">
              <Typography className="font-light">
                保険料が安くメリットの多いITSに加入しています。（付加金給付・保養施設利用補助等）
              </Typography>
            </Stack>
          </Stack>
        </Paper>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper elevation={8} className="min-h-[150px] h-full rounded-lg">
          <Stack className="p-5">
            <Stack className="flex-row items-center gap-2 mb-2">
              <img src="/static/auth-icon.png" alt="auth" className="w-8 h-8" />
              <Typography className="text-lg text-[#1D5DBA] font-medium">
                資格取得制度
              </Typography>
            </Stack>
            <Stack className="pl-8 gap-2">
              <Typography className="font-light">
                会社指定の資格を取得した場合に受験料・報奨金を支給します。
              </Typography>
            </Stack>
          </Stack>
        </Paper>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper elevation={8} className="min-h-[150px] h-full rounded-lg">
          <Stack className="p-5">
            <Stack className="flex-row items-center gap-2 mb-2">
              <img
                src="/static/people-02-icon.png"
                alt="people-02"
                className="w-8 h-8"
              />
              <Typography className="text-lg text-[#1D5DBA] font-medium">
                その他
              </Typography>
            </Stack>
            <Stack className="pl-8 gap-4">
              <Typography className="text-[#1D5DBA] font-medium">
                ・時間外手当全額支給・交通費全額支給
              </Typography>
              <Stack className="gap-2">
                <Typography className="text-[#1D5DBA] font-medium">
                  ・社宅制度
                </Typography>
                <Typography className="font-light pl-4">
                  事務所から徒歩圏内（1.5km圏内目安）で会社借上げにて社宅が利用できます。家賃月額半分の補助が出ます。（上限5万円）
                </Typography>
              </Stack>
              <Stack className="gap-2">
                <Typography className="text-[#1D5DBA] font-medium">
                  ・ロックの日
                </Typography>
                <Typography className="font-light pl-4">
                  ロック好きな社長が作ったロックの日！7〜9月の間に1日お休みが取れます。
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Paper>
      </Grid>
    </Grid>
  )
}
