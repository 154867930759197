import Message from 'components/About/Message'
import Purpose from 'components/About/Purpose'
import Top from 'components/About/Top'
import Value from 'components/About/Value'
import Vision from 'components/About/Vision'
import { Helmet } from 'react-helmet'

export default function About() {
  return (
    <>
      <Helmet>
        <title>フォーシーズンズの想い</title>
      </Helmet>
      <Top />
      <Message />
      <Purpose />
      <Vision />
      <Value />
    </>
  )
}
