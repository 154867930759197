import { Box, Stack, Typography } from '@mui/material'

export default function Top() {
  return (
    <Box className="h-[350px] relative">
      <Stack className="bg-recruit"></Stack>
      <Stack className="relative text-white z-10 container h-full">
        <Stack className="h-full w-full justify-center">
          <Stack className="gap-6 w-full max-w-[450px] p-4">
            <Stack className="lg:items-center items-start text-shadow">
              <Stack className="justify-center lg:items-center">
                <Box
                  component="img"
                  src="/static/recruit-white.svg"
                  className="drop-shadow-lg"
                ></Box>
                <Typography className="font-medium" mt={1}>
                  採用情報
                </Typography>
              </Stack>
            </Stack>
            <Stack className="text-shadow lg:text-center">
              <Typography className="font-medium">
                共に助け合い高め合う。
              </Typography>
              <Typography className="font-medium">
                私たちの大切な想いを同じくする仲間を募集しています。
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  )
}
