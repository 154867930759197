import About from 'pages/About'
import Culture from 'pages/Culture'
import Fellow from 'pages/Fellow'
import FellowDetail from 'pages/Fellow/Detail'
import Home from 'pages/Home'
import NotFound from 'pages/NotFound'
import RecruitPage from 'pages/Recruit'
import ApplicationPage from 'pages/Recruit/Application'
import applicationComplete from 'pages/Recruit/Application/Complete'
import applicationError from 'pages/Recruit/Application/Error'
import WorkPage from 'pages/Work'

interface RouterType {
  path: string
  title?: string
  element: (props: any) => JSX.Element | null
  children?: RouterType[]
  role: 0 | 9 | 999
}

export const dialogRoutes: RouterType[] = []
export const privateRoutes: RouterType[] = [
  {
    path: '/',
    element: Home,
    role: 0,
  },
  {
    path: '/about',
    element: About,
    role: 0,
  },
  {
    path: '/culture',
    element: Culture,
    role: 0,
  },
  {
    path: '/fellow',
    element: Fellow,
    role: 0,
  },
  {
    path: '/fellow/:id',
    element: FellowDetail,
    role: 0,
  },
  {
    path: '/work',
    element: WorkPage,
    role: 0,
  },
  {
    path: '/recruit/application/complete',
    element: applicationComplete,
    role: 0,
  },
  {
    path: '/recruit/application/error',
    element: applicationError,
    role: 0,
  },
  {
    path: '/recruit/application',
    element: ApplicationPage,
    role: 0,
  },
  {
    path: '/recruit',
    element: RecruitPage,
    role: 0,
  },
  {
    path: '/recruit/:pos',
    element: RecruitPage,
    role: 0,
  },
  {
    path: '/not-found',
    element: NotFound,
    role: 0,
  },
]
