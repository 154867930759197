import { Box, Grid, Paper, Stack, Typography } from '@mui/material'

export default function Committee() {
  return (
    <Box mt={8}>
      <Grid
        container
        spacing={{
          xs: 2,
          md: 3,
          lg: 5,
        }}
        alignItems="stretch"
      >
        <Grid item xs={12}>
          <Typography component="h4" className="heading-3">
            委員会活動
          </Typography>
          <Typography>
            会社の課題解決や、部署間交流を目的として委員会活動を行っています。
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={8} className="min-h-[150px] h-full rounded-lg p-10">
            <Grid container spacing={4} alignItems="stretch">
              <Grid item xs={12} md={6}>
                <Stack className="gap-4">
                  <Stack className="flex-row items-center gap-2">
                    <img
                      src="/static/event-icon.png"
                      alt="event"
                      className="w-8 h-8"
                    />
                    <Typography className="text-lg text-[#1D5DBA] font-medium">
                      イベント開催委員会
                    </Typography>
                  </Stack>
                  <Typography className="font-light">
                    コミュニケーション活性のための社内イベントを企画運営する委員会です。
                  </Typography>
                </Stack>
              </Grid>
              {/* <Grid item xs={12} md={6}>
                <Stack className="gap-4">
                  <Stack className="flex-row items-center gap-2">
                    <img
                      src="/static/committee-icon.png"
                      alt="committee"
                      className="w-8 h-8"
                    />
                    <Typography className="text-lg text-[#1D5DBA] font-medium">
                      新規制度導入委員会
                    </Typography>
                  </Stack>
                  <Typography className="font-light">
                    社員による社員のための制度を検討・管理部門へ交渉し、新しい制度導入を目指す委員会です。
                  </Typography>
                </Stack>
              </Grid> */}
              <Grid item xs={12} md={6}>
                <Stack className="gap-4">
                  <Stack className="flex-row items-center gap-2">
                    <img
                      src="/static/marketing-icon.png"
                      alt="marketing"
                      className="w-8 h-8"
                    />
                    <Typography className="text-lg text-[#1D5DBA] font-medium">
                      マーケティング委員会
                    </Typography>
                  </Stack>
                  <Typography className="font-light">
                    会社の情報発信を行う委員会。noteやtwitterで日々の出来事や自由な発信を行っています。
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack className="gap-4">
                  <Stack className="flex-row items-center gap-2">
                    <img
                      src="/static/seminal-icon.png"
                      alt="seminal"
                      className="w-8 h-8"
                    />
                    <Typography className="text-lg text-[#1D5DBA] font-medium">
                      勉強会セミナー委員会
                    </Typography>
                  </Stack>
                  <Typography className="font-light">
                    会社や個人のレベルアップを図るべく、勉強会やセミナーの開催や情報発信を行う委員会です。
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  )
}
