import { Box, Stack, Typography } from '@mui/material'

export default function Top() {
  return (
    <Box className="h-[350px] relative">
      <Stack className="absolute inset-0">
        <Box
          component="img"
          src="/static/fellow-top.png"
          alt="fellow-top"
          className="w-full h-full object-cover"
        ></Box>
      </Stack>
      <Stack className="relative z-10 container h-full w-full justify-center items-center text-white">
        <Stack className="gap-6">
          <Stack className="items-center text-shadow">
            <Stack className="justify-center items-center">
              <Box
                component="img"
                src="/static/fellow-white.svg"
                className="drop-shadow-md"
              ></Box>
              <Typography className="font-medium" mt={1}>
                仲間たち
              </Typography>
            </Stack>
          </Stack>
          <Stack className="justify-center items-center text-shadow">
            <Typography className="font-medium text-sm lg:text-base text-center">
              フォーシーズンズにはどんな先輩たちがいるのか、
            </Typography>
            <Typography className="font-medium text-sm lg:text-base text-center flex flex-col">
              どんな想いを持っているのか。仲間たちを紹介します。
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  )
}
