import { Box } from '@mui/material'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { getRecruits } from 'app/slices/Recruit'
import { UIAction } from 'app/slices/UI'
import Career from 'components/Recruit/Career'
import Category from 'components/Recruit/Category'
import Newbie from 'components/Recruit/Newbie'
import Top from 'components/Recruit/Top'
import { useEffect, useLayoutEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'

export default function RecruitPage() {
  let { pos } = useParams<{ pos: string }>()
  const dispatch = useAppDispatch()
  const status = useAppSelector((s) => s.Recruit.status)
  const data = useAppSelector((s) => s.Recruit.list)
  useEffect(() => {
    if (status === 'init') dispatch(getRecruits())
  }, [status, dispatch])
  const newbie = data.filter((v) => v.group === '新卒採用')
  const career = data.filter((v) => v.group === 'キャリア採用')
  useLayoutEffect(() => {
    if (pos) {
      const timer = setInterval(() => {
        if (document.querySelector(`#${pos}`)) {
          dispatch(UIAction.setTarget(`#${pos}`))
          clearInterval(timer)
          clearTimeout(stopTime)
        }
      }, 100)
      const stopTime = setTimeout(() => {
        if (timer) {
          clearInterval(timer)
        }
      }, 5000)
      return () => {
        clearInterval(timer)
        clearTimeout(stopTime)
      }
    }
  }, [dispatch, pos])
  return (
    <Box>
      <Helmet>
        <title>フォーシーズンズ | 採用情報</title>
      </Helmet>
      <Top />
      <Category />
      {newbie.length > 0 && <Newbie data={newbie} />}
      {career.length > 0 && <Career data={career} />}
    </Box>
  )
}
