import { Box } from '@mui/material'
import Discussion from 'components/Fellow/Discussion'
import Interview from 'components/Fellow/Interview'
import Top from 'components/Fellow/Top'
import FellowSlide from 'components/Home/FellowSlide'
import { Helmet } from 'react-helmet'

export default function Fellow() {
  return (
    <Box className="pb-10">
      <Helmet>
        <title>フォーシーズンズ | 仲間たち</title>
      </Helmet>
      <Top />
      <Interview />
      <Discussion />
      <FellowSlide heading={false} />
    </Box>
  )
}
