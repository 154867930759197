import { Box, Stack, Typography } from '@mui/material'

export default function Top() {
  return (
    <Box className="h-[350px] relative">
      <Stack className="absolute inset-0">
        <Box
          component="img"
          src="/static/about-top.png"
          alt="about-top"
          className="w-full h-full object-cover"
          sx={{
            objectPosition: 'center 35%',
          }}
        ></Box>
      </Stack>
      <Stack className="relative text-white z-10 container h-full">
        <Stack className="h-full w-full justify-center gap-6">
          <Stack className="items-start text-shadow">
            <Stack className="justify-center items-center ml-10">
              <Box component="img" src="/static/about-white.svg"></Box>
              <Typography className="font-medium" mt={1}>
                フォーシーズンズの想い
              </Typography>
            </Stack>
          </Stack>
          <Stack className="text-shadow">
            <Typography className="font-medium">
              事業やサービスはあくまで手段。そもそも私たちはなんのためにビジネスをするのか。
            </Typography>
            <Typography className="font-medium">
              サービスを通してどんな社会を作りたいのか。そのために私たちが大切にしている想い。
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  )
}
