interface Props {
  className?: string
}
export default function Arrow({ className }: Props) {
  return (
    <svg
      className={className}
      style={{ height: '16px' }}
      viewBox="0 0 49 18"
      fill="none"
    >
      <path d="M10.625 7.09082H0V10.6283H10.625V7.09082Z" fill="currentColor" />
      <path
        className="opacity-30"
        d="M16.7747 7.09082H13.6997V10.6283H16.7747V7.09082Z"
        fill="currentColor"
      />
      <path
        className="opacity-50"
        d="M22.9124 7.09082H19.8374V10.6283H22.9124V7.09082Z"
        fill="currentColor"
      />
      <path
        className="opacity-80"
        d="M29.0623 7.09082H25.9873V10.6283H29.0623V7.09082Z"
        fill="currentColor"
      />
      <path
        d="M29.0623 17.7158C28.6123 17.7158 28.1498 17.5408 27.8123 17.1908C27.1248 16.5033 27.1248 15.3783 27.8123 14.6908L33.6373 8.86582L27.7998 3.02832C27.1123 2.34082 27.1123 1.21582 27.7998 0.52832C28.4873 -0.15918 29.6123 -0.15918 30.2998 0.52832L37.3873 7.61582C38.0748 8.30332 38.0748 9.42832 37.3873 10.1158L30.2998 17.2033C29.9498 17.5533 29.4998 17.7283 29.0498 17.7283L29.0623 17.7158Z"
        fill="currentColor"
      />
      <path
        className="opacity-50"
        d="M39.6873 17.7031C39.2373 17.7031 38.7748 17.5281 38.4373 17.1781C37.7498 16.4906 37.7498 15.3656 38.4373 14.6781L44.2623 8.85312L38.4248 3.01562C37.7373 2.32812 37.7373 1.20313 38.4248 0.515625C39.1248 -0.171875 40.2373 -0.171875 40.9248 0.515625L48.0123 7.61563C48.6998 8.30313 48.6998 9.42813 48.0123 10.1156L40.9248 17.1906C40.5748 17.5406 40.1248 17.7156 39.6748 17.7156L39.6873 17.7031Z"
        fill="currentColor"
      />
    </svg>
  )
}
