import { Box, Stack, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

export default function Food() {
  return (
    <Box className="container py-10">
      <Stack className="items-center justify-center gap-4 mb-8">
        <Typography className="heading-2" id="飲食事業">
          飲食事業
        </Typography>
        <Typography textAlign="center">
          竹岡式ラーメンの提供、具にこだわったおにぎりの販売から
          <br />
          仕出し弁当の配達、音楽イベント出店まで幅広く展開
        </Typography>
      </Stack>
      <Typography className="font-light">
        IT部門の従業員の思い付き発言から始まった飲食事業。初期のITカフェ業態から様々な出会いやチャンスで事業が広がり、現在の形となっています。まだまだ試行錯誤の段階ですが、お客様の笑顔のため味はもちろんのこと心に響く、そんな商品を提供していきます。
      </Typography>
      <Stack gap={10} my={4}>
        {/* matsuura */}
        <Stack className="gap-2 py-4">
          <Stack className="flex-row items-center gap-2">
            <span className="w-4 h-4 bg-[#31AFB6]"></span>
            <Typography className="font-medium">
              仕出し 松うら（おにぎり／R15カレー／お惣菜）
            </Typography>
          </Stack>
          <Stack className="lg:flex-row gap-[5%]">
            <Box className="flex-1">
              <Box
                component="img"
                src="/static/onigiri.png"
                alt="onigiri"
                className="w-full"
              />
            </Box>
            <Stack className="flex-1 items-start justify-start gap-4">
              <Stack className="flex-1 gap-4 items-start justify-start">
                <Typography className="font-medium ">
                  定番から変わり種まで、ボリューム満点のこだわりのおにぎりと、作りたてのお惣菜を店頭にて販売
                </Typography>
                <Typography className="text-sm">
                  「具を食べるおにぎり」をコンセプトに、幅広いメニューを取り揃えております。唐揚げやとん汁、コールスローなどのお惣菜と、カレーライスも店頭にて販売しています。
                </Typography>
              </Stack>
              <Link to="https://nihombashimatsuura.com/" target="_blank">
                <Box>
                  <Box
                    component="img"
                    src="/static/matsuura.png"
                    alt="matsuura"
                    className="w-full"
                  />
                </Box>
              </Link>
            </Stack>
          </Stack>
        </Stack>
        {/* shidashi bentou */}
        <Stack className="gap-2 py-4">
          <Stack className="flex-row items-center gap-2">
            <span className="w-4 h-4 bg-[#31AFB6]"></span>
            <Typography className="font-medium">
              仕出し 松うら（仕出し弁当）
            </Typography>
          </Stack>
          <Stack className="lg:flex-row gap-[5%]">
            <Box className="flex-1">
              <Box
                component="img"
                src="/static/bentou.png"
                alt="bentou"
                className="w-full"
              />
            </Box>
            <Stack className="flex-1 items-start justify-start gap-4">
              <Stack className="flex-1 gap-4 items-start justify-start">
                <Typography className="font-medium">
                  ロケ弁、会議や研修に、
                  <br />
                  手作りのお弁当を都内近郊へお届け
                </Typography>
                <Typography className="text-sm">
                  撮影やロケ、セミナーや会議、法事などに、手作りの美味しいお弁当を、23区および近郊へお届けしています。
                </Typography>
              </Stack>
              <Link
                to="https://nihombashimatsuura.com/catering"
                target="_blank"
              >
                <Box>
                  <Box
                    component="img"
                    src="/static/shidashi.png"
                    alt="shidashi"
                    className="w-full"
                  />
                </Box>
              </Link>
            </Stack>
          </Stack>
        </Stack>
        {/* ramen marutake */}
        <Stack className="gap-2 py-4">
          <Stack className="flex-row items-center gap-2">
            <span className="w-4 h-4 bg-[#31AFB6]"></span>
            <Typography className="font-medium">まる竹</Typography>
          </Stack>
          <Stack className="lg:flex-row gap-[5%]">
            <Box className="flex-1">
              <Box
                component="img"
                src="/static/ramen.png"
                alt="ramen"
                className="w-full"
              />
            </Box>
            <Stack className="flex-1 items-start justify-start gap-4">
              <Stack className="flex-1 gap-4 items-start justify-start">
                <Typography className="font-medium">
                  千葉県内房（富津市竹岡）発祥の竹岡式ラーメンと、出汁薫る黄色いカレーライスのお店
                </Typography>
                <Typography className="text-sm">
                  スープは富津宮醤油がしみ込んだ柔らかい煮豚の醤油ダレをお湯で割るだけ。癖になる細く縮れた麺を、シンプルなスープでいただくラーメンです。
                </Typography>
              </Stack>
              <Link to="https://marutake.0004s.com/" target="_blank">
                <Box>
                  <Box
                    component="img"
                    src="/static/marutake.png"
                    alt="marutake"
                    className="w-full"
                  />
                </Box>
              </Link>
            </Stack>
          </Stack>
        </Stack>
        {/* bookshelf */}
        <Stack className="gap-2 py-4">
          <Stack className="flex-row items-center gap-2">
            <span className="w-4 h-4 bg-[#D86357]"></span>
            <Typography className="font-medium">
              イベント出店（BOOKSHELF CAFE）
            </Typography>
          </Stack>
          <Stack className="lg:flex-row gap-[5%]">
            <Box className="flex-1">
              <Box
                component="img"
                src="/static/event.png"
                alt="event"
                className="w-full"
              />
            </Box>
            <Stack className="flex-1 items-start justify-start gap-4">
              <Stack className="flex-1 gap-4 items-start justify-start">
                <Typography className="font-medium">
                  「フェスが好き」から始まったイベント出店、安全・安心な営業で、祝祭空間に幸せをお届け
                </Typography>
                <Typography className="text-sm">
                  数々の音楽フェスティバル、食のイベントに参加してきた確かな実績と、安全・安心の営業が強みです。看板メニューの「果実丸ごとスムージー」をはじめとした、様々なメニューをお届けしています。
                </Typography>
              </Stack>
              <Link to="https://nihombashimatsuura.com/event" target="_blank">
                <Box>
                  <Box
                    component="img"
                    src="/static/bookshelf.png"
                    alt="bookshelf"
                    className="w-full"
                  />
                </Box>
              </Link>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  )
}
